import React, { FC, memo, useMemo } from 'react';
import styles from './RateOverview.module.scss';
import { Rating, Text } from '../../atom';

export type RateItem = { rate: number; count: number };

interface Props {
  total?: number;
  avgRate?: number;
  rates?: Array<RateItem>;
  className?: string;
}
const rateNames = ['5', '4', '3', '2', '1'];
const RateOverView: FC<Props> = ({ total = 0, className, rates }) => {
  const renderRates = useMemo(() => {
    return rateNames?.map(item => {
      const count = rates?.find(value => value.rate === Number(item))?.count;

      return (
        <span key={item} className={styles.rate_overView_overview_item}>
          <Text className={styles.text}>{item}</Text>
          <span className={styles.row}>
            <span
              className={styles.filled}
              style={{ width: `${count ? (count / total) * 100 : 0}%` }}
            />
          </span>
        </span>
      );
    });
  }, [rates, total]);

  const avgRate = useMemo(() => {
    const avg =
      rates?.reduce((acc, curr) => {
        return acc + curr.rate * curr.count;
      }, 0) || 0;

    return avg / total;
  }, [rates, total]);

  return (
    <div className={[styles.rate_overView, className].join(' ')}>
      <div className={styles.rate_overView_count}>
        <Text className={styles.avgRate}>{avgRate || 0}</Text>
        <Rating count={avgRate} />
        <Text className={styles.all}>{`${total} reviews`}</Text>
      </div>
      <div className={styles.rate_overView_overview}>{renderRates}</div>
    </div>
  );
};

export default memo(RateOverView);
