export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type Ad = {
  createdAt: Scalars['DateTime']['output'];
  endDate: Scalars['DateTime']['output'];
  feed: Feed;
  feedId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  todayViewCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  venue?: Maybe<Venue>;
  venueId?: Maybe<Scalars['Int']['output']>;
};

export type AddFeedLike = {
  feedId: Scalars['Int']['input'];
};

export type AdminReqListInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<AdminReqStatusesEnum>;
};

export enum AdminReqRolesEnum {
  Admin = 'admin',
  CompanyManager = 'companyManager',
  EventManager = 'eventManager',
  VenueManager = 'venueManager'
}

export enum AdminReqStatusesEnum {
  Accepted = 'accepted',
  Pending = 'pending',
  Rejected = 'rejected'
}

export type AdminRequests = {
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  role: AdminReqRolesEnum;
  status: AdminReqStatusesEnum;
  updatedAt: Scalars['DateTime']['output'];
  venue?: Maybe<Venue>;
  venueId?: Maybe<Scalars['Int']['output']>;
};

export enum AuthProviderEnum {
  Apple = 'apple',
  Facebook = 'facebook',
  Google = 'google'
}

export type BlockUserInput = {
  userId: Scalars['Int']['input'];
};

export type Blog = {
  content: Scalars['String']['output'];
  cover?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isPublished: Scalars['Boolean']['output'];
  media?: Maybe<Array<MediaObject>>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CancelCheckoutSessionInput = {
  checkoutSessionId: Scalars['String']['input'];
  ticketId: Scalars['Int']['input'];
};

export type Category = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Chat = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  isGroup: Scalars['Boolean']['output'];
  latestMessage: Scalars['Int']['output'];
  message?: Maybe<Message>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  users: Array<Scalars['Int']['output']>;
};

export type ChatMessageModel = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isEdited: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  sender?: Maybe<User>;
  status?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type ChatUser = {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  profile_image?: Maybe<MediaObject>;
};

export type CheckAppVersionModel = {
  hasUpdate: Scalars['Boolean']['output'];
};

export type CheckIsUserBlockedWithChatIdModel = {
  isBlocked?: Maybe<Scalars['Boolean']['output']>;
};

export type CheckOtpInput = {
  otp: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirm: Scalars['String']['input'];
  sessionToken: Scalars['String']['input'];
};

export type CheckOtpModel = {
  token: Scalars['String']['output'];
  user: User;
};

export type CheckinInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  media?: InputMaybe<Array<MediaInput>>;
  venueId: Scalars['Int']['input'];
};

export type CheckinQuantityByMonthModel = {
  count: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
};

export type CheckoutSessionInput = {
  paymentCancelUrl: Scalars['String']['input'];
  paymentSuccessUrl: Scalars['String']['input'];
};

export type ContactsForSupport = {
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ContactsListModel = {
  contacts: Array<ContactsForSupport>;
  count: Scalars['Int']['output'];
};

export type CountFollowRequests = {
  count: Scalars['Int']['output'];
};

export type CountNotSeenNotificationsModel = {
  count: Scalars['Int']['output'];
};

export type CountObject = {
  count: Scalars['Int']['output'];
};

export type CreateAdInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['DateTime']['input'];
  hashtagIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  media?: InputMaybe<Array<MediaInput>>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<FeedType>;
  venueId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateAdminInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  permissions: Array<Scalars['Int']['input']>;
};

export type CreateAdminRequestInput = {
  email: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  role: AdminReqRolesEnum;
  venueId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateBlogInput = {
  content: Scalars['String']['input'];
  cover?: InputMaybe<Scalars['String']['input']>;
  media?: InputMaybe<Array<MediaInput>>;
  title: Scalars['String']['input'];
};

export type CreateContactInfoInput = {
  email: Scalars['String']['input'];
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
};

export type CreateDiscountInput = {
  code: Scalars['String']['input'];
  currency?: InputMaybe<CurrencyEnum>;
  description: Scalars['String']['input'];
  discount: Scalars['Float']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  type: DiscountTypeEnum;
  venueId: Scalars['Int']['input'];
};

export type CreateEventInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  bandName?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  cover?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['String']['input'];
  hashtagIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  media?: InputMaybe<MediaInput>;
  name: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Int']['input']>;
  saleExpireDate: Scalars['String']['input'];
  saleStartDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  venueId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateFeedComment = {
  comment: Scalars['String']['input'];
  feedId: Scalars['Int']['input'];
  parentId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateHashtagInput = {
  name: Scalars['String']['input'];
};

export type CreatePostInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  media?: InputMaybe<Array<MediaInput>>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<FeedType>;
  venueId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateTicketInput = {
  amount?: Scalars['Float']['input'];
  count: Scalars['Int']['input'];
  currency?: InputMaybe<CurrencyEnum>;
  description: Scalars['String']['input'];
  earlyBirdAmount?: InputMaybe<Scalars['Int']['input']>;
  earlyBirdCount?: InputMaybe<Scalars['Int']['input']>;
  endTime: Scalars['DateTime']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  media?: InputMaybe<MediaInput>;
  name: Scalars['String']['input'];
  published?: InputMaybe<Scalars['Boolean']['input']>;
  startTime: Scalars['DateTime']['input'];
  ticketableId: Scalars['Int']['input'];
  ticketableType: TicketableTypeEnum;
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type CreateVenueComment = {
  comment: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['Int']['input']>;
  venueId: Scalars['Int']['input'];
};

export type CreateVenueInput = {
  address: Scalars['String']['input'];
  capacity?: InputMaybe<Scalars['String']['input']>;
  categoryId: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  googlePlaceId?: InputMaybe<Scalars['String']['input']>;
  internationalPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  linkToFaceBook?: InputMaybe<Scalars['String']['input']>;
  linkToInstagram?: InputMaybe<Scalars['String']['input']>;
  linkToWebsite?: InputMaybe<Scalars['String']['input']>;
  linkToYoutube?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<VenueLocationInput>;
  logo?: InputMaybe<Scalars['String']['input']>;
  managers?: InputMaybe<Array<VenueManagerInput>>;
  media?: InputMaybe<Array<MediaInput>>;
  name: Scalars['String']['input'];
  profitMarginAmount?: InputMaybe<Scalars['Float']['input']>;
  profitMarginCurrency?: InputMaybe<CurrencyEnum>;
  workingHours: Array<WorkingHoursInput>;
};

export type CreateVenueManagerInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  venueId: Scalars['Int']['input'];
};

export type CreateVenueRate = {
  rate: Scalars['Int']['input'];
  venueId: Scalars['Int']['input'];
};

export type CreateVenueStaffInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  venueId: Scalars['Int']['input'];
};

export type CreateVenueStreamInput = {
  name: Scalars['String']['input'];
  streamUrl: Scalars['String']['input'];
  venueId: Scalars['Int']['input'];
};

export enum CurrencyEnum {
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD'
}

export type DeleteInput = {
  id: Scalars['Int']['input'];
  type: FavoritesTypeEnum;
};

export type DeleteReportedFeedInput = {
  feedId: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
};

export type Deleted = {
  id: Scalars['Int']['output'];
  message: Scalars['String']['output'];
};

export type Discount = {
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Int']['output']>;
  createdByUser?: Maybe<User>;
  currency: CurrencyEnum;
  deleted: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  discount: Scalars['Float']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  type: DiscountTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
  venueId?: Maybe<Scalars['Int']['output']>;
};

export enum DiscountTypeEnum {
  Amount = 'amount',
  Percent = 'percent'
}

export type Event = {
  address?: Maybe<Scalars['String']['output']>;
  adminUserId?: Maybe<Scalars['Int']['output']>;
  bandName?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  cover?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['DateTime']['output'];
  eventRequest?: Maybe<EventRequests>;
  favorite?: Maybe<Favorites>;
  feed: Feed;
  hasEarlyBirdTickets: Scalars['Boolean']['output'];
  hasTicket: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isSoldOut: Scalars['Boolean']['output'];
  location: VenueLocationObject;
  media?: Maybe<Array<MediaObject>>;
  name: Scalars['String']['output'];
  price?: Maybe<Scalars['Int']['output']>;
  profitMarginAmount: Scalars['Int']['output'];
  saleExpireDate?: Maybe<Scalars['DateTime']['output']>;
  saleStartDate?: Maybe<Scalars['DateTime']['output']>;
  savedByMe: Scalars['Boolean']['output'];
  startDate: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
  tickets?: Maybe<Array<Ticket>>;
  timeZone: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  venue?: Maybe<Venue>;
  venueId?: Maybe<Scalars['Int']['output']>;
};

export type EventObject = {
  event: Event;
  feed: Feed;
  hashtagIds: Array<Scalars['Int']['output']>;
};

export enum EventReqStatusEnum {
  Accepted = 'accepted',
  Pending = 'pending',
  Rejected = 'rejected'
}

export type EventRequests = {
  createdAt: Scalars['DateTime']['output'];
  creatorId: Scalars['Int']['output'];
  event: Event;
  eventId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  venueId: Scalars['Int']['output'];
};

export type EventRequestsInput = {
  eventId?: InputMaybe<Scalars['Int']['input']>;
};

export enum EventStatusesEnum {
  Active = 'active',
  Canceled = 'canceled',
  Expired = 'expired'
}

export type EventsCountArgsInput = {
  status: EventStatusesEnum;
};

export type Favorites = {
  createdAt: Scalars['DateTime']['output'];
  event: Event;
  eventId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  type?: Maybe<FavoritesTypeEnum>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['Int']['output'];
  venue: Venue;
  venueId?: Maybe<Scalars['Int']['output']>;
};

export enum FavoritesTypeEnum {
  Event = 'EVENT',
  Venue = 'VENUE'
}

export type Feed = {
  ad?: Maybe<Ad>;
  cover?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Event>;
  eventId?: Maybe<Scalars['Int']['output']>;
  hashtags?: Maybe<Array<HashtagFeed>>;
  id: Scalars['Int']['output'];
  isPublished: Scalars['Boolean']['output'];
  isShared: Scalars['Boolean']['output'];
  likedByMe: Scalars['Boolean']['output'];
  media?: Maybe<Array<MediaObject>>;
  parent?: Maybe<Feed>;
  parentId?: Maybe<Scalars['Int']['output']>;
  privacy: Scalars['String']['output'];
  timeZone: Scalars['String']['output'];
  total?: Maybe<Scalars['Int']['output']>;
  totalComments: Scalars['Int']['output'];
  totalLikes: Scalars['Int']['output'];
  totalShares: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']['output']>;
  venue?: Maybe<Venue>;
  venueId?: Maybe<Scalars['Int']['output']>;
};

export type FeedComment = {
  comment: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  feed: Feed;
  id: Scalars['Int']['output'];
  likeCount: Scalars['Int']['output'];
  parent?: Maybe<FeedComment>;
  replyCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type FeedLike = {
  createdAt: Scalars['DateTime']['output'];
  feed: Feed;
  id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export enum FeedType {
  Ad = 'AD',
  Checkin = 'CHECKIN',
  Event = 'EVENT',
  Post = 'POST'
}

export enum FilterStatus {
  Active = 'active',
  Expired = 'expired',
  Sold = 'sold',
  Unstarted = 'unstarted'
}

export type ForgotPasswordInput = {
  email: Scalars['String']['input'];
};

export type ForgotPasswordModel = {
  message: Scalars['String']['output'];
  sessionToken: Scalars['String']['output'];
};

export type GetAdsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GetAdsObject = {
  ads: Array<Feed>;
  count: Scalars['Int']['output'];
};

export type GetAlertsObject = {
  alerts: Array<Feed>;
};

export type GetAllStreamsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  venueId: Scalars['Int']['input'];
};

export type GetAllVenueStreamsEntity = {
  totalCount: Scalars['Int']['output'];
  venueStreams: Array<VenueStreams>;
};

export type GetAppUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetAppUsersModel = {
  count: Scalars['Int']['output'];
  users: Array<User>;
};

export type GetChatIdModel = {
  id: Scalars['Int']['output'];
};

export type GetCountModel = {
  count: Scalars['Int']['output'];
};

export type GetFcmTokensModel = {
  tokens: Array<Scalars['String']['output']>;
};

export type GetFeedCommentInput = {
  feedId: Scalars['Int']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetFeedCommentReplyInput = {
  commentId: Scalars['Int']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetFeedLikesCountInput = {
  feedId: Scalars['Int']['input'];
};

export type GetFeedLikesCountModel = {
  count: Scalars['Int']['output'];
  lastLikedUser: Scalars['String']['output'];
  likedByMe: Scalars['Boolean']['output'];
};

export type GetFeedsArgs = {
  adsCount: Scalars['Int']['input'];
  extraFeeds: Scalars['Boolean']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetFeedsByUserIdInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['Int']['input'];
};

export type GetFeedsObject = {
  adsCount: Scalars['Int']['output'];
  extraFeeds: Scalars['Boolean']['output'];
  feeds: Array<Feed>;
};

export type GetFollowersByUserIdInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['Int']['input'];
};

export type GetFollowingsByUserIdInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['Int']['input'];
};

export type GetHashtags = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type GetMessagePresignedUrlInput = {
  chatId: Scalars['Int']['input'];
  duration?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  repliedTo?: InputMaybe<Scalars['Int']['input']>;
  type: Scalars['String']['input'];
};

export type GetMessagesInput = {
  chatId: Scalars['Int']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GetOneItemInput = {
  id: Scalars['Int']['input'];
  type: FavoritesTypeEnum;
};

export type GetPreSignedUrlInput = {
  key: Scalars['String']['input'];
};

export type GetPresignedUrlModel = {
  message: Message;
  url: Scalars['String']['output'];
};

export type GetRatesCountGroupedModel = {
  ratesTotalCount: Scalars['Int']['output'];
  venueRatesGrouped: Array<GroupedSubType>;
};

export type GetReportedFeedsModel = {
  count: Scalars['Int']['output'];
  reports: Array<ReportedFeed>;
};

export type GetReportedPostAnalyticModel = {
  abuse: Scalars['Int']['output'];
  hate_speech: Scalars['Int']['output'];
  nudity: Scalars['Int']['output'];
  other: Scalars['Int']['output'];
  spam: Scalars['Int']['output'];
  violence: Scalars['Int']['output'];
};

export type GetTransactionsByTicketIdInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ticketId: Scalars['Int']['input'];
};

export type GetUserModel = {
  isBlocked?: Maybe<Scalars['Boolean']['output']>;
  isFollow: Scalars['Boolean']['output'];
  isRequestedFollow: Scalars['Boolean']['output'];
  user: User;
};

export type GetVenueComments = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  venueId: Scalars['Int']['input'];
};

export type GetVisitedPlacesInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['Int']['input'];
};

export type Global = {
  message: Scalars['String']['output'];
  status: Scalars['Int']['output'];
};

export type GroupChatModel = {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  participants: Scalars['Int']['output'];
  users: Array<ChatUser>;
};

export type GroupedSubType = {
  rate: Scalars['Int']['output'];
  rateGroupCount?: Maybe<Scalars['Int']['output']>;
};

export type Hashtag = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type HashtagFeed = {
  createdAt: Scalars['DateTime']['output'];
  feed: Feed;
  feedId: Scalars['Int']['output'];
  hashtag: Hashtag;
  hashtagId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type HashtagUser = {
  createdAt: Scalars['DateTime']['output'];
  hashtag: Hashtag;
  hashtagId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['Int']['output'];
};

export type HistoryLogs = {
  action: Scalars['JSON']['output'];
  actionType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  event?: Maybe<Event>;
  id: Scalars['Int']['output'];
  resource: Scalars['String']['output'];
  resourceId: Scalars['Int']['output'];
  ticket?: Maybe<Ticket>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['Int']['output'];
  venue?: Maybe<Venue>;
};

export type HistoryLogsModel = {
  count: Scalars['Int']['output'];
  logs: Array<HistoryLogs>;
};

export type LikeFeed = {
  liked: Scalars['Boolean']['output'];
};

export type LikedEventsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkHashtagsToFeedInput = {
  feedId: Scalars['Int']['input'];
  hashtagIds: Array<Scalars['Int']['input']>;
};

export type ListAdminObject = {
  admins: Array<User>;
  count: Scalars['Int']['output'];
};

export type ListAdminReqsModel = {
  adminRequests: Array<AdminRequests>;
  count: Scalars['Int']['output'];
};

export type ListAdminsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type ListAllContactsForSupportInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ListAllSettingsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ListAllSettingsModel = {
  count: Scalars['Int']['output'];
  settings?: Maybe<Array<Settings>>;
};

export type ListBlogModel = {
  blogs: Array<Blog>;
  count: Scalars['Int']['output'];
};

export type ListBlogsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ListChatsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ListChatsModel = {
  chat_image?: Maybe<MediaObject>;
  hasUnreadMessage: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isMuted: Scalars['Boolean']['output'];
  message: ChatMessageModel;
  name?: Maybe<Scalars['String']['output']>;
  participants: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type ListDiscountsInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  venueId?: InputMaybe<Scalars['Int']['input']>;
};

export type ListDiscountsModel = {
  count: Scalars['Int']['output'];
  discounts: Array<Discount>;
};

export type ListEventFeedsByVenueIdInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  venueId: Scalars['Int']['input'];
};

export type ListEventFeedsByVenueIdModel = {
  count: Scalars['Int']['output'];
  feeds: Array<Feed>;
};

export type ListEventReqsInput = {
  creatorId?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<EventReqStatusEnum>;
};

export type ListEventRequestsObject = {
  count: Scalars['Int']['output'];
  eventRequests: Array<EventRequests>;
};

export type ListEventTicketsAsAdminInput = {
  eventId: Scalars['Int']['input'];
  filterStatus: FilterStatus;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ListEventsInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  eventStatus?: InputMaybe<EventStatusesEnum>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchValue?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  venueId?: InputMaybe<Scalars['Int']['input']>;
};

export type ListEventsObject = {
  count: Scalars['Int']['output'];
  events: Array<Event>;
};

export type ListEventsWebInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
  searchValue?: InputMaybe<Scalars['String']['input']>;
  venueId?: InputMaybe<Scalars['Int']['input']>;
};

export type ListFavoritesByTypeInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchValue?: InputMaybe<Scalars['String']['input']>;
};

export type ListFeedLikers = {
  background_image?: Maybe<MediaObject>;
  bio?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  followers: Scalars['Int']['output'];
  followings: Scalars['Int']['output'];
  hashtags: Array<HashtagUser>;
  id: Scalars['Int']['output'];
  isFollow: Scalars['Boolean']['output'];
  isRequestedFollow: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  online: Scalars['Boolean']['output'];
  password: Scalars['String']['output'];
  permissions?: Maybe<Array<Scalars['Int']['output']>>;
  profile_image?: Maybe<MediaObject>;
  provider?: Maybe<Scalars['String']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  salt: Scalars['String']['output'];
  status: Scalars['String']['output'];
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ListFeedLikersInput = {
  feedId: Scalars['Int']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ListHashtagsModel = {
  count: Scalars['Int']['output'];
  hashtags?: Maybe<Array<Hashtag>>;
};

export type ListLogsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderType?: InputMaybe<OrderTypeEnum>;
  searchValue?: InputMaybe<Scalars['String']['input']>;
};

export type ListPostsModel = {
  count: Scalars['Int']['output'];
  posts: Array<Feed>;
};

export type ListTicketsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ListTicketsModel = {
  count: Scalars['Int']['output'];
  tickets: Array<Ticket>;
};

export type ListTransactionsModel = {
  count: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  rows: Array<Transaction>;
};

export type ListVenueArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ListVenueManagersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  venueId?: InputMaybe<Scalars['Int']['input']>;
};

export type ListVenueManagersObject = {
  count: Scalars['Int']['output'];
  venueManagers?: Maybe<Array<VenueManager>>;
};

export type ListVenueStaffArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ListVenueStaffObject = {
  count: Scalars['Int']['output'];
  venueStaffs: Array<VenueStaff>;
};

export type ListVenuesObject = {
  count: Scalars['Int']['output'];
  venues: Array<Venue>;
};

export type ListVenuesWebInput = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchValue?: InputMaybe<Scalars['String']['input']>;
};

export type Location = {
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type LoginWithSocialInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  provider: AuthProviderEnum;
  token: Scalars['String']['input'];
};

export type MakeFavoriteInput = {
  eventId?: InputMaybe<Scalars['Int']['input']>;
  type: FavoritesTypeEnum;
  venueId?: InputMaybe<Scalars['Int']['input']>;
};

export type MediaInput = {
  contentLength?: InputMaybe<Scalars['Int']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCover?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  sizes?: InputMaybe<Array<Scalars['String']['input']>>;
  type: Scalars['String']['input'];
};

export type MediaObject = {
  contentLength: Scalars['Int']['output'];
  contentType: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isCover?: Maybe<Scalars['Boolean']['output']>;
  key: Scalars['String']['output'];
  sizes?: Maybe<Array<Scalars['String']['output']>>;
  type: Scalars['String']['output'];
};

export type Message = {
  chatId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  duration: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isEdited: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  readBy: Array<Scalars['Int']['output']>;
  sender: User;
  senderId: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Mutation = {
  acceptEventRequest: EventRequests;
  acceptFollowRequest: Success;
  addFcmToken: Success;
  blockUser: UserBlocked;
  cancelCheckoutSession: Success;
  changeEventStatus: Event;
  changeMedia: Success;
  checkDiscountIsUnique: Success;
  checkOtp: CheckOtpModel;
  checkin: Feed;
  createAd: Feed;
  createAdmin: User;
  createAdminRequest: Success;
  createBlog: Blog;
  createContactInfo: Success;
  createDiscount: Discount;
  createEvent: Event;
  createEventRequest: EventRequests;
  createFeedComment: FeedComment;
  createHashtag: Hashtag;
  createPost: Feed;
  createSubscriber: Success;
  createTicket: Ticket;
  createUser: UserLogin;
  createVenue: Venue;
  createVenueComment: VenueComment;
  createVenueManager: User;
  createVenueRate: VenueRate;
  createVenueStaff: VenueStaff;
  createVenueStreams: VenueStreams;
  deleteChat: Deleted;
  deleteContactInfo: Deleted;
  deleteFcmToken: Success;
  deleteFromFavorite: Success;
  deleteReportedFeed: Success;
  deleteUser: Success;
  deleteVenueStaff: Deleted;
  forgotPassAdmins: ForgotPasswordModel;
  forgotPassword: ForgotPasswordModel;
  getChatId: GetChatIdModel;
  getMessagePreSignedUrl: GetPresignedUrlModel;
  getPresignedUrl: PreSignedUrlModel;
  ignoreReportedFeed: Success;
  likeFeed: LikeFeed;
  linkHashtagsToFeed: Array<Hashtag>;
  linkHashtagsToUser: Array<Hashtag>;
  login: UserLogin;
  loginAdmins: UserLogin;
  loginWithSocial: UserLogin;
  makeFavorite: Favorites;
  markAsUnread: Global;
  muteChat: Global;
  orderTicket: OrderTicketModel;
  orderTicketWeb: OrderTicketWebModel;
  rejectEventRequest: EventRequests;
  rejectFollowRequest: Success;
  removeAd: Deleted;
  removeAdmin: Deleted;
  removeAdminRequest: Deleted;
  removeBlog: Deleted;
  removeDiscount: Success;
  removeEvent: Deleted;
  removeEventRequest: Deleted;
  removeFeed: Deleted;
  removeFollower: Success;
  removeHashtag: Deleted;
  removeHashtagFromFeed: Deleted;
  removeHashtagFromUser: Deleted;
  removeNotification: Success;
  removePost: Deleted;
  removeTicket: Success;
  removeVenue: Deleted;
  removeVenueManager: Deleted;
  removeVenueStreams: Deleted;
  replaceFcmToken: Success;
  reportFeed: Success;
  sendFeedback: Success;
  sendFollowRequest: Success;
  sendMessageToVenueStaff: Success;
  setSeenNotifications: Success;
  sharePost: Feed;
  unblockUser: Success;
  unfollowUser: Success;
  updateAd: Feed;
  updateAdmin: User;
  updateAdminRequestStatus: Success;
  updateBlog: Blog;
  updateChatName: Global;
  updateContactInfo: Success;
  updateDiscount: Discount;
  updateEvent: Event;
  updateFeed: Feed;
  updatePassword: UserLogin;
  updatePost: Feed;
  updatePublishedState: Event;
  updateSettings: Settings;
  updateTicket: Ticket;
  updateUser: User;
  updateUserStatus: User;
  updateVenue: Venue;
  updateVenueStaff: VenueStaff;
  updateVenueStreams: VenueStreams;
  uploadMediaFromAdmin: Success;
  verifyQrCode: Success;
};


export type MutationAcceptEventRequestArgs = {
  id: Scalars['Int']['input'];
};


export type MutationAcceptFollowRequestArgs = {
  fromUserId: Scalars['Int']['input'];
};


export type MutationAddFcmTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationBlockUserArgs = {
  blockUserArgs: BlockUserInput;
};


export type MutationCancelCheckoutSessionArgs = {
  cancelCheckoutSessionArgs: CancelCheckoutSessionInput;
};


export type MutationChangeEventStatusArgs = {
  updateEventStatusInput: UpdateEventStatusInput;
};


export type MutationCheckDiscountIsUniqueArgs = {
  code: Scalars['String']['input'];
};


export type MutationCheckOtpArgs = {
  checkOtpData: CheckOtpInput;
};


export type MutationCheckinArgs = {
  checkinInput: CheckinInput;
};


export type MutationCreateAdArgs = {
  createAdInput: CreateAdInput;
};


export type MutationCreateAdminArgs = {
  createAdminInput: CreateAdminInput;
};


export type MutationCreateAdminRequestArgs = {
  createAdminRequestInput: CreateAdminRequestInput;
};


export type MutationCreateBlogArgs = {
  createBlogInput: CreateBlogInput;
};


export type MutationCreateContactInfoArgs = {
  createContactInfoInput: CreateContactInfoInput;
};


export type MutationCreateDiscountArgs = {
  input: CreateDiscountInput;
};


export type MutationCreateEventArgs = {
  createEventInput: CreateEventInput;
};


export type MutationCreateEventRequestArgs = {
  eventReqsArgs: EventRequestsInput;
};


export type MutationCreateFeedCommentArgs = {
  createFeedCommentInput: CreateFeedComment;
};


export type MutationCreateHashtagArgs = {
  createHashtagInput: CreateHashtagInput;
};


export type MutationCreatePostArgs = {
  createPostInput: CreatePostInput;
};


export type MutationCreateSubscriberArgs = {
  email: Scalars['String']['input'];
};


export type MutationCreateTicketArgs = {
  input: CreateTicketInput;
};


export type MutationCreateUserArgs = {
  createUserData: CreateUserInput;
};


export type MutationCreateVenueArgs = {
  createVenueInput: CreateVenueInput;
};


export type MutationCreateVenueCommentArgs = {
  createVenueCommentInput: CreateVenueComment;
};


export type MutationCreateVenueManagerArgs = {
  createVenueManagerInput: CreateVenueManagerInput;
};


export type MutationCreateVenueRateArgs = {
  createVenueRateInput: CreateVenueRate;
};


export type MutationCreateVenueStaffArgs = {
  createVenueStaffInput: CreateVenueStaffInput;
};


export type MutationCreateVenueStreamsArgs = {
  input: CreateVenueStreamInput;
};


export type MutationDeleteChatArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteContactInfoArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteFcmTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationDeleteFromFavoriteArgs = {
  deleteInput: DeleteInput;
};


export type MutationDeleteReportedFeedArgs = {
  deleteReportedFeedArgs: DeleteReportedFeedInput;
};


export type MutationDeleteVenueStaffArgs = {
  venueStaffId: Scalars['Int']['input'];
};


export type MutationForgotPassAdminsArgs = {
  forgotPasswordData: ForgotPasswordInput;
};


export type MutationForgotPasswordArgs = {
  forgotPasswordData: ForgotPasswordInput;
};


export type MutationGetChatIdArgs = {
  userId: Scalars['Int']['input'];
};


export type MutationGetMessagePreSignedUrlArgs = {
  getMessagePreSignedUrlArgs: GetMessagePresignedUrlInput;
};


export type MutationGetPresignedUrlArgs = {
  getPreSignedUrlInput: GetPreSignedUrlInput;
};


export type MutationIgnoreReportedFeedArgs = {
  id: Scalars['Int']['input'];
};


export type MutationLikeFeedArgs = {
  createFeedLikeInput: AddFeedLike;
};


export type MutationLinkHashtagsToFeedArgs = {
  linkHashtagsToFeedInput: LinkHashtagsToFeedInput;
};


export type MutationLinkHashtagsToUserArgs = {
  ids: Array<Scalars['Int']['input']>;
};


export type MutationLoginArgs = {
  userCredentials: UserLoginDto;
};


export type MutationLoginAdminsArgs = {
  userCredentials: UserLoginDto;
};


export type MutationLoginWithSocialArgs = {
  userCredentials: LoginWithSocialInput;
};


export type MutationMakeFavoriteArgs = {
  makeFavoriteInput: MakeFavoriteInput;
};


export type MutationMarkAsUnreadArgs = {
  chatId: Scalars['Int']['input'];
};


export type MutationMuteChatArgs = {
  chatId: Scalars['Int']['input'];
};


export type MutationOrderTicketArgs = {
  input: OrderTicketInput;
};


export type MutationOrderTicketWebArgs = {
  input: OrderTicketInputWeb;
};


export type MutationRejectEventRequestArgs = {
  rejectEventReqArgs: RejectEventReqInput;
};


export type MutationRejectFollowRequestArgs = {
  fromUserId: Scalars['Int']['input'];
};


export type MutationRemoveAdArgs = {
  adId: Scalars['Int']['input'];
};


export type MutationRemoveAdminArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveAdminRequestArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveBlogArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveDiscountArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveEventArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveEventRequestArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveFeedArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveFollowerArgs = {
  userId: Scalars['Int']['input'];
};


export type MutationRemoveHashtagArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveHashtagFromFeedArgs = {
  removeHashtagFromFeedInput: RemoveHashtagFromFeedInput;
};


export type MutationRemoveHashtagFromUserArgs = {
  hashtagId: Scalars['Int']['input'];
};


export type MutationRemoveNotificationArgs = {
  notificationId: Scalars['Int']['input'];
};


export type MutationRemovePostArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveTicketArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveVenueArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveVenueManagerArgs = {
  venueManagerUserId: Scalars['Int']['input'];
};


export type MutationRemoveVenueStreamsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationReplaceFcmTokenArgs = {
  replaceFcmTokenInput: ReplaceFcmTokenInput;
};


export type MutationReportFeedArgs = {
  reportFeedInput: ReportFeedInput;
};


export type MutationSendFeedbackArgs = {
  sendFeedbackArgs: SendFeedbackInput;
};


export type MutationSendFollowRequestArgs = {
  userId: Scalars['Int']['input'];
};


export type MutationSendMessageToVenueStaffArgs = {
  sendMessageToVenueStaffInput: SendMessageToVenueStaffInput;
};


export type MutationSetSeenNotificationsArgs = {
  notificationsIds: Array<Scalars['Int']['input']>;
};


export type MutationSharePostArgs = {
  shareFeedInput: ShareFeedInput;
};


export type MutationUnblockUserArgs = {
  userId: Scalars['Int']['input'];
};


export type MutationUnfollowUserArgs = {
  userId: Scalars['Int']['input'];
};


export type MutationUpdateAdArgs = {
  updateAdInput: UpdateAdInput;
};


export type MutationUpdateAdminArgs = {
  updateAdminInput: UpdateAdminInput;
};


export type MutationUpdateAdminRequestStatusArgs = {
  updateAdminReqStatusInput: UpdateAdminReqStatusInput;
};


export type MutationUpdateBlogArgs = {
  updateBlogInput: UpdateBlogInput;
};


export type MutationUpdateChatNameArgs = {
  updateChatNameArgs: UpdateChatNameInput;
};


export type MutationUpdateContactInfoArgs = {
  updateContactInfoInput: UpdateContactInfoInput;
};


export type MutationUpdateDiscountArgs = {
  input: UpdateDiscountInput;
};


export type MutationUpdateEventArgs = {
  updateEventInput: UpdateEventInput;
};


export type MutationUpdateFeedArgs = {
  updateFeedInput: UpdateFeedInput;
};


export type MutationUpdatePasswordArgs = {
  updatePasswordInput: UpdatePasswordInput;
};


export type MutationUpdatePostArgs = {
  updatePostInput: UpdatePostInput;
};


export type MutationUpdatePublishedStateArgs = {
  updatePublishedStateInput: UpdatePublishedStateInput;
};


export type MutationUpdateSettingsArgs = {
  updateSettingsInput: UpdateSettingsInput;
};


export type MutationUpdateTicketArgs = {
  input: UpdateTicketInput;
};


export type MutationUpdateUserArgs = {
  updateUser: UpdateUserInput;
};


export type MutationUpdateUserStatusArgs = {
  updateUserStatusArgs: UpdateUserStatusInput;
};


export type MutationUpdateVenueArgs = {
  updateVenueInput: UpdateVenueInput;
};


export type MutationUpdateVenueStaffArgs = {
  updateVenueStaffInput: UpdateVenueStaffInput;
};


export type MutationUpdateVenueStreamsArgs = {
  input: UpdateVenueStreamInput;
};


export type MutationUploadMediaFromAdminArgs = {
  uploadMediaFromAdminInput: UploadMediaFromAdminInput;
};


export type MutationVerifyQrCodeArgs = {
  id: Scalars['String']['input'];
};

export type Note = {
  amount: Scalars['Int']['output'];
  boughtEarlyBirdQuantity: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  earlyBirdAvailableCount: Scalars['Int']['output'];
};

export type Notification = {
  action: Scalars['String']['output'];
  body?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  event?: Maybe<Event>;
  feedComment?: Maybe<FeedComment>;
  feedLike?: Maybe<FeedLike>;
  id: Scalars['Int']['output'];
  isSeen?: Maybe<Scalars['Boolean']['output']>;
  notificationableId: Scalars['Int']['output'];
  notificationableType: Scalars['String']['output'];
  senderUser?: Maybe<User>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userFollower?: Maybe<UserFollower>;
  userId: Scalars['Int']['output'];
};

export type OrderTicketInput = {
  checkoutSessionParams?: InputMaybe<CheckoutSessionInput>;
  discountId?: InputMaybe<Scalars['Int']['input']>;
  initialCount?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
  ticketId: Scalars['Int']['input'];
};

export type OrderTicketInputWeb = {
  discountId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
  ticketId: Scalars['Int']['input'];
};

export type OrderTicketModel = {
  checkoutSessionId?: Maybe<Scalars['String']['output']>;
  checkoutSessionUrl: Scalars['String']['output'];
  note?: Maybe<Note>;
};

export type OrderTicketWebModel = {
  clientSecret?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Note>;
};

export enum OrderTypeEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OtherReasonsObject = {
  createdAt: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  user: ReasonUserObject;
};

export type PaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type Photo = {
  height: Scalars['Int']['output'];
  imageLink: Scalars['String']['output'];
  photo_reference: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

export type Place = {
  icon: Scalars['String']['output'];
  location: Location;
  name: Scalars['String']['output'];
  photos: Array<Photo>;
  placeID: Scalars['String']['output'];
};

export type PostChartData = {
  count: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
};

export enum PostsSortTypeEnum {
  Total = 'total',
  TotalComments = 'totalComments',
  TotalLikes = 'totalLikes',
  TotalShares = 'totalShares'
}

export type PreSignedUrlModel = {
  key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type QrCode = {
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isVerified: Scalars['Boolean']['output'];
  ticket: Ticket;
  transaction: Transaction;
  updatedAt: Scalars['DateTime']['output'];
  verifierUser?: Maybe<User>;
};

export type Query = {
  checkAppVersion: CheckAppVersionModel;
  checkIsUserBlockedWithChatId: CheckIsUserBlockedWithChatIdModel;
  countFollowRequests: CountFollowRequests;
  countNotSeenNotifications: CountNotSeenNotificationsModel;
  evenRequestsCount: CountObject;
  feed: Feed;
  findDiscountByCodeAndVenueId: Discount;
  findDiscountById: Discount;
  findEvent: EventObject;
  findEventInfo: Event;
  findEventRequest: EventRequests;
  findEventWeb: Event;
  findOneContactInfo: ContactsForSupport;
  findTicket: Ticket;
  findTicketByEventId: Array<Ticket>;
  getAdminRequestsLists: ListAdminReqsModel;
  getAdmins: ListAdminObject;
  getAds: GetAdsObject;
  getAlerts: GetAlertsObject;
  getAllCategories: Array<Category>;
  getAllTransactions: ListTransactionsModel;
  getAppUsers?: Maybe<GetAppUsersModel>;
  getBestVenues: Array<Venue>;
  getBlockedUsers: Array<User>;
  getCheckinQuantityByMonth: Array<CheckinQuantityByMonthModel>;
  getEventsCount: GetCountModel;
  getFcmTokens: GetFcmTokensModel;
  getFeedHashtags: Array<HashtagFeed>;
  getFeedLikesCount: GetFeedLikesCountModel;
  getFeeds: GetFeedsObject;
  getFeedsByUserId: Array<Feed>;
  getFollowersByUserId: Array<User>;
  getFollowingsByUserId: Array<User>;
  getGroupChat: GroupChatModel;
  getLast20Events: Array<Event>;
  getMe: User;
  getMessages: Array<Message>;
  getMyFollowers: Array<User>;
  getMyFollowings: Array<User>;
  getMySharedFeeds: Array<Feed>;
  getOneBlog: Blog;
  getOneBlogWeb: Blog;
  getOneFavoriteItem: Favorites;
  getOneSetting: Settings;
  getPlaces: Array<Place>;
  getPost: Feed;
  getReportedFeed: ReportedFeed;
  getReportedFeeds: GetReportedFeedsModel;
  getReportedPostAnalytics: GetReportedPostAnalyticModel;
  getSalesAnalytics: SalesAnalyticsModel;
  getTransactionsByTicketId: ListTransactionsModel;
  getUser?: Maybe<GetUserModel>;
  getUserFeed: Array<Feed>;
  getUserHashtags: Array<HashtagUser>;
  getUserQuantityAnalytics: Array<UserAnalyticsModel>;
  getVenue: Venue;
  getVenueComments: Array<VenueComment>;
  getVenueCommentsAndRatesCount: VenueRateCommentCountModel;
  getVenueCount: GetCountModel;
  getVenuePreview?: Maybe<Venue>;
  getVenueRatesCountWeb: GetRatesCountGroupedModel;
  getVenueStream: VenueStreams;
  getVenueStreams: GetAllVenueStreamsEntity;
  getVenueWeb: Venue;
  getVisitedPlaces: Array<Venue>;
  listAllContacts: ContactsListModel;
  listAllSettings: ListAllSettingsModel;
  listBlogs: ListBlogModel;
  listBlogsWeb: ListBlogModel;
  listChats: Array<ListChatsModel>;
  listDiscounts: ListDiscountsModel;
  listEventFeedsByVenueId: ListEventFeedsByVenueIdModel;
  listEventRequests: ListEventRequestsObject;
  listEventTicketsAsAdmin: ListTicketsModel;
  listEvents: ListEventsObject;
  listEventsWeb: ListEventsObject;
  listFavoriteEvents: ListEventsObject;
  listFavoriteVenues: ListVenuesObject;
  listFeedComments: Array<FeedComment>;
  listFeedCommentsReply: Array<FeedComment>;
  listFeedLikers: Array<ListFeedLikers>;
  listFollowRequests: Array<UserFollower>;
  listHashtags: ListHashtagsModel;
  listLikedEvents: ListEventsObject;
  listLogs: HistoryLogsModel;
  listNotifications: Array<Notification>;
  listOrderedTickets: Array<QrCode>;
  listPosts: ListPostsModel;
  listTickets: ListTicketsModel;
  listVenueStaff: ListVenueStaffObject;
  listVenues: ListVenuesObject;
  listVenuesWeb: ListVenuesObject;
  searchUser: Array<GetUserModel>;
  shareLikeCommentsCount: ShareLikeCommentsCountModel;
  venueManagersList: ListVenueManagersObject;
};


export type QueryCheckAppVersionArgs = {
  version: Scalars['String']['input'];
};


export type QueryCheckIsUserBlockedWithChatIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryEvenRequestsCountArgs = {
  getEventRequestsCountArgs: GetEventRequestsCountInput;
};


export type QueryFeedArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindDiscountByCodeAndVenueIdArgs = {
  code: Scalars['String']['input'];
  venueId: Scalars['Int']['input'];
};


export type QueryFindDiscountByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindEventArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindEventInfoArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindEventRequestArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindEventWebArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindOneContactInfoArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindTicketArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFindTicketByEventIdArgs = {
  eventId: Scalars['Int']['input'];
};


export type QueryGetAdminRequestsListsArgs = {
  adminReqListInput: AdminReqListInput;
};


export type QueryGetAdminsArgs = {
  getAdminsArgs: ListAdminsInput;
};


export type QueryGetAdsArgs = {
  getAdsArgs: GetAdsInput;
};


export type QueryGetAlertsArgs = {
  getAlertsInput: PaginationInput;
};


export type QueryGetAllTransactionsArgs = {
  input: PaginationInput;
};


export type QueryGetAppUsersArgs = {
  getAppUsersArgs: GetAppUsersArgs;
};


export type QueryGetBlockedUsersArgs = {
  getBlockedUsersArgs: PaginationInput;
};


export type QueryGetEventsCountArgs = {
  eventsCountArgs: EventsCountArgsInput;
};


export type QueryGetFeedHashtagsArgs = {
  feedId: Scalars['Int']['input'];
};


export type QueryGetFeedLikesCountArgs = {
  getFeedLikesCountArgs: GetFeedLikesCountInput;
};


export type QueryGetFeedsArgs = {
  getFeedsArgs: GetFeedsArgs;
};


export type QueryGetFeedsByUserIdArgs = {
  getFeedsByUserIdArgs: GetFeedsByUserIdInput;
};


export type QueryGetFollowersByUserIdArgs = {
  getFollowersByUserIdArgs: GetFollowersByUserIdInput;
};


export type QueryGetFollowingsByUserIdArgs = {
  getFollowingsByUserIdArgs: GetFollowingsByUserIdInput;
};


export type QueryGetGroupChatArgs = {
  users: Array<Scalars['Int']['input']>;
};


export type QueryGetMessagesArgs = {
  getMessagesArgs: GetMessagesInput;
};


export type QueryGetMyFollowersArgs = {
  getMyFollowersArgs: PaginationInput;
};


export type QueryGetMyFollowingsArgs = {
  getMyFollowingsArgs: PaginationInput;
};


export type QueryGetMySharedFeedsArgs = {
  getMySharedFeedsArgs: PaginationInput;
};


export type QueryGetOneBlogArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetOneBlogWebArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetOneFavoriteItemArgs = {
  getOneItemInput: GetOneItemInput;
};


export type QueryGetOneSettingArgs = {
  key: Scalars['String']['input'];
};


export type QueryGetPlacesArgs = {
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  openNow?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetPostArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetReportedFeedArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetReportedFeedsArgs = {
  getReportedFeedsArgs: PaginationInput;
};


export type QueryGetTransactionsByTicketIdArgs = {
  input: GetTransactionsByTicketIdInput;
};


export type QueryGetUserArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetUserFeedArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetVenueArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetVenueCommentsArgs = {
  getVenueCommentsArgs: GetVenueComments;
};


export type QueryGetVenuePreviewArgs = {
  googlePlaceId: Scalars['String']['input'];
};


export type QueryGetVenueRatesCountWebArgs = {
  venueId: Scalars['Int']['input'];
};


export type QueryGetVenueStreamArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetVenueStreamsArgs = {
  input: GetAllStreamsInput;
};


export type QueryGetVenueWebArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetVisitedPlacesArgs = {
  getVisitedPlacesInput: GetVisitedPlacesInput;
};


export type QueryListAllContactsArgs = {
  listAllInput: ListAllContactsForSupportInput;
};


export type QueryListAllSettingsArgs = {
  listAllSettingsInput: ListAllSettingsInput;
};


export type QueryListBlogsArgs = {
  ListBlogsInput: ListBlogsInput;
};


export type QueryListBlogsWebArgs = {
  ListBlogsInput: ListBlogsInput;
};


export type QueryListChatsArgs = {
  listChatArgs: ListChatsInput;
};


export type QueryListDiscountsArgs = {
  input: ListDiscountsInput;
};


export type QueryListEventFeedsByVenueIdArgs = {
  input: ListEventFeedsByVenueIdInput;
};


export type QueryListEventRequestsArgs = {
  listEventReqsArgs: ListEventReqsInput;
};


export type QueryListEventTicketsAsAdminArgs = {
  input: ListEventTicketsAsAdminInput;
};


export type QueryListEventsArgs = {
  listEventsArgs: ListEventsInput;
};


export type QueryListEventsWebArgs = {
  listEventsArgs: ListEventsWebInput;
};


export type QueryListFavoriteEventsArgs = {
  listFavoritesByTypeInput: ListFavoritesByTypeInput;
};


export type QueryListFavoriteVenuesArgs = {
  listFavoritesByTypeInput: ListFavoritesByTypeInput;
};


export type QueryListFeedCommentsArgs = {
  getFeedCommentArgs: GetFeedCommentInput;
};


export type QueryListFeedCommentsReplyArgs = {
  getFeedCommentReplyArgs: GetFeedCommentReplyInput;
};


export type QueryListFeedLikersArgs = {
  listFeedLikersArgs: ListFeedLikersInput;
};


export type QueryListFollowRequestsArgs = {
  PaginationInput: PaginationInput;
};


export type QueryListHashtagsArgs = {
  getHashtagsArgs: GetHashtags;
};


export type QueryListLikedEventsArgs = {
  likedEventsArgs: LikedEventsInput;
};


export type QueryListLogsArgs = {
  ListLogsInputArgs: ListLogsInput;
};


export type QueryListNotificationsArgs = {
  paginationInput: PaginationInput;
};


export type QueryListOrderedTicketsArgs = {
  pagination: PaginationInput;
};


export type QueryListPostsArgs = {
  listPostsArgs: ListPosts;
};


export type QueryListTicketsArgs = {
  input: ListTicketsInput;
};


export type QueryListVenueStaffArgs = {
  listVenueStaffArgs: ListVenueStaffArgs;
};


export type QueryListVenuesArgs = {
  listVenuesArgs: ListVenueArgs;
};


export type QueryListVenuesWebArgs = {
  listVenueWebArgs: ListVenuesWebInput;
};


export type QuerySearchUserArgs = {
  searchUserArgs: SearchUserInput;
};


export type QueryVenueManagersListArgs = {
  venueManagersListArgs: ListVenueManagersArgs;
};

export type ReasonUserObject = {
  name: Scalars['String']['output'];
};

export type RemoveHashtagFromFeedInput = {
  feedId: Scalars['Int']['input'];
  hashtagId: Scalars['Int']['input'];
};

export type ReplaceFcmTokenInput = {
  newToken: Scalars['String']['input'];
  oldToken: Scalars['String']['input'];
};

export type ReportFeedInput = {
  feedId: Scalars['Int']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  type: ReportedFeedType;
};

export type ReportedFeed = {
  createdAt: Scalars['DateTime']['output'];
  feed: Feed;
  feedId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  reportCount: Scalars['Int']['output'];
  reports: Array<ReportedFeedObject>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['Int']['output'];
};

export type ReportedFeedObject = {
  count: Scalars['Int']['output'];
  otherReasonMessages?: Maybe<Array<OtherReasonsObject>>;
  reason: Scalars['String']['output'];
  type: ReportedFeedType;
};

export enum ReportedFeedType {
  Abuse = 'ABUSE',
  HateSpeech = 'HATE_SPEECH',
  Nudity = 'NUDITY',
  Other = 'OTHER',
  Spam = 'SPAM',
  Violence = 'VIOLENCE'
}

export type SalesAnalyticsModel = {
  earlyBirdSales: Scalars['Int']['output'];
  ticketSales: Scalars['Int']['output'];
  totalRefunds: Scalars['Int']['output'];
  totalSales: Scalars['Int']['output'];
};

export type SearchUserInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SendFeedbackInput = {
  feedback: Scalars['String']['input'];
};

export type SendMessageToVenueStaffInput = {
  body: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  venueStaffIds: Array<Scalars['Int']['input']>;
};

export type Settings = {
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type ShareFeedInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  feedId: Scalars['Int']['input'];
};

export type ShareLikeCommentsCountModel = {
  postChartData: Array<PostChartData>;
  totalComments: Scalars['Int']['output'];
  totalLikes: Scalars['Int']['output'];
  totalShares: Scalars['Int']['output'];
};

export type Success = {
  message: Scalars['String']['output'];
  status: Scalars['Int']['output'];
};

export type Ticket = {
  amount: Scalars['Float']['output'];
  availableEarlyBird: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: CurrencyEnum;
  deleted: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  earlyBirdAmount?: Maybe<Scalars['Int']['output']>;
  earlyBirdCount?: Maybe<Scalars['Int']['output']>;
  endTime?: Maybe<Scalars['DateTime']['output']>;
  event?: Maybe<Event>;
  expireDate: Scalars['DateTime']['output'];
  feedId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Array<MediaObject>>;
  name: Scalars['String']['output'];
  published: Scalars['Boolean']['output'];
  reserveCount: Scalars['Int']['output'];
  saleAmount: Scalars['Float']['output'];
  soldCount: Scalars['Int']['output'];
  startDate: Scalars['DateTime']['output'];
  startTime?: Maybe<Scalars['DateTime']['output']>;
  ticketableId?: Maybe<Scalars['Int']['output']>;
  ticketableType: TicketableTypeEnum;
  transactions?: Maybe<Array<Transaction>>;
  updatedAt: Scalars['DateTime']['output'];
  venue?: Maybe<Venue>;
};

export enum TicketableTypeEnum {
  Event = 'event',
  Venue = 'venue'
}

export type Transaction = {
  amount: Scalars['Float']['output'];
  bookingFee: Scalars['Int']['output'];
  checkoutSessionId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currency: CurrencyEnum;
  discount?: Maybe<Discount>;
  discountAmount: Scalars['Float']['output'];
  earlyBirdAmount: Scalars['Float']['output'];
  earlyBirdQuantity: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  paymentIntentId?: Maybe<Scalars['String']['output']>;
  qrCode?: Maybe<Array<QrCode>>;
  quantity: Scalars['Int']['output'];
  state?: Maybe<TransactionStateEnum>;
  status: TransactionStatusEnum;
  ticket?: Maybe<Ticket>;
  ticketAmount: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export enum TransactionStateEnum {
  Pending = 'pending',
  Transferred = 'transferred'
}

export enum TransactionStatusEnum {
  Canceled = 'canceled',
  Initiated = 'initiated',
  Paid = 'paid',
  Pending = 'pending',
  Refund = 'refund'
}

export type UpdateAdInput = {
  adId: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  hashtagIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  venueId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateAdminInput = {
  adminUserId: Scalars['Int']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UpdateAdminReqStatusInput = {
  id: Scalars['Int']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  status: AdminReqStatusesEnum;
};

export type UpdateBlogInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  cover?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  media?: InputMaybe<Array<MediaInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateChatNameInput = {
  chatId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type UpdateContactInfoInput = {
  id: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDiscountInput = {
  code: Scalars['String']['input'];
  currency?: InputMaybe<CurrencyEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['Float']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<DiscountTypeEnum>;
};

export type UpdateEventInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  bandName?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  cover?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  media?: InputMaybe<Array<MediaInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  saleExpireDate?: InputMaybe<Scalars['String']['input']>;
  saleStartDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  venueId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateEventStatusInput = {
  id: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
  status: EventStatusesEnum;
};

export type UpdateFeedInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  bandName?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  featuredImageUrl?: InputMaybe<MediaInput>;
  files?: InputMaybe<Array<MediaInput>>;
  formattedAddress?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  images?: InputMaybe<Array<MediaInput>>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<VenueLocationInput>;
  media?: InputMaybe<Array<MediaInput>>;
  openingHours?: InputMaybe<WorkingHoursInput>;
  price?: InputMaybe<Scalars['Int']['input']>;
  privacy?: InputMaybe<Scalars['String']['input']>;
  slogan?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  venueId?: InputMaybe<Scalars['Int']['input']>;
  videos?: InputMaybe<Array<MediaInput>>;
};

export type UpdatePasswordInput = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type UpdateSettingsInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type UpdateTicketInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  currency?: InputMaybe<CurrencyEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  earlyBirdAmount?: InputMaybe<Scalars['Int']['input']>;
  earlyBirdCount?: InputMaybe<Scalars['Int']['input']>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['Int']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateUserInput = {
  background_image?: InputMaybe<MediaInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  profile_image?: InputMaybe<MediaInput>;
};

export type UpdateUserStatusInput = {
  id: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
  status: UserStatusesEnum;
};

export type UpdateVenueInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  capacity?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  cover?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  googlePlaceId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  internationalPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  linkToFaceBook?: InputMaybe<Scalars['String']['input']>;
  linkToInstagram?: InputMaybe<Scalars['String']['input']>;
  linkToWebsite?: InputMaybe<Scalars['String']['input']>;
  linkToYoutube?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<VenueLocationInput>;
  logo?: InputMaybe<Scalars['String']['input']>;
  managers?: InputMaybe<Array<VenueManagerInput>>;
  media?: InputMaybe<Array<MediaInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  profitMarginAmount?: InputMaybe<Scalars['Float']['input']>;
  profitMarginCurrency?: InputMaybe<CurrencyEnum>;
  workingHours: Array<WorkingHoursInput>;
};

export type UpdateVenueStaffInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  venueStaffId: Scalars['Int']['input'];
};

export type UpdateVenueStreamInput = {
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  streamUrl?: InputMaybe<Scalars['String']['input']>;
  venueId?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadMediaFromAdminInput = {
  id: Scalars['Int']['input'];
  media: Array<MediaInput>;
  type: Scalars['String']['input'];
};

export type User = {
  background_image?: Maybe<MediaObject>;
  bio?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  followers: Scalars['Int']['output'];
  followings: Scalars['Int']['output'];
  hashtags: Array<HashtagUser>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  online: Scalars['Boolean']['output'];
  password: Scalars['String']['output'];
  permissions?: Maybe<Array<Scalars['Int']['output']>>;
  profile_image?: Maybe<MediaObject>;
  provider?: Maybe<Scalars['String']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  salt: Scalars['String']['output'];
  status: Scalars['String']['output'];
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserAnalyticsModel = {
  count: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
};

export type UserBlocked = {
  createdAt: Scalars['DateTime']['output'];
  from: Scalars['Int']['output'];
  fromUser: User;
  id: Scalars['Int']['output'];
  to: Scalars['Int']['output'];
  toUser: User;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserFollower = {
  createdAt: Scalars['DateTime']['output'];
  from: Scalars['Int']['output'];
  fromUser: User;
  id: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  to: Scalars['Int']['output'];
  toUser: User;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserLogin = {
  token: Scalars['String']['output'];
  user: User;
};

export type UserLoginDto = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
};

export enum UserStatusesEnum {
  Active = 'active',
  Blocked = 'blocked',
  Deleted = 'deleted'
}

export type Venue = {
  address: Scalars['String']['output'];
  avgRate: Scalars['Int']['output'];
  cameraNumber: Scalars['Int']['output'];
  capacity?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['Int']['output']>;
  comments: Array<VenueComment>;
  cover?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  featuredImageUrl?: Maybe<MediaObject>;
  feeds: Array<Feed>;
  googlePlaceId?: Maybe<Scalars['String']['output']>;
  hasTicket: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  internationalPhoneNumber?: Maybe<Scalars['String']['output']>;
  isSoldOut: Scalars['Boolean']['output'];
  linkToFaceBook?: Maybe<Scalars['String']['output']>;
  linkToInstagram?: Maybe<Scalars['String']['output']>;
  linkToWebsite?: Maybe<Scalars['String']['output']>;
  linkToYoutube?: Maybe<Scalars['String']['output']>;
  location?: Maybe<VenueLocationObject>;
  logo?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Array<MediaObject>>;
  name: Scalars['String']['output'];
  ownRate?: Maybe<Scalars['Int']['output']>;
  profitMarginAmount: Scalars['Int']['output'];
  profitMarginCurrency: Scalars['String']['output'];
  savedByMe: Scalars['Boolean']['output'];
  timeZone?: Maybe<Scalars['String']['output']>;
  totalComments: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  venue?: Maybe<Favorites>;
  venueManagers: Array<VenueManager>;
  workingHours: Array<WorkingHoursObject>;
};

export type VenueComment = {
  comment: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  likeCount: Scalars['Int']['output'];
  parent?: Maybe<VenueComment>;
  replyCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  venue: Venue;
};

export type VenueLocationInput = {
  coordinates: Array<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type VenueLocationObject = {
  coordinates: Array<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type VenueManager = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  venue: Venue;
};

export type VenueManagerInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type VenueRate = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  rate: Scalars['Int']['output'];
  rateGroupCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  venue: Venue;
};

export type VenueRateCommentCountModel = {
  venueCommentsCount: Scalars['Int']['output'];
  venueRatesCount: Scalars['Int']['output'];
};

export type VenueStaff = {
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  venue: Venue;
  venueId: Scalars['Int']['output'];
};

export type VenueStreams = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  streamUrl: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  venue: Venue;
  venueId: Scalars['Int']['output'];
};

export type WorkingHoursInput = {
  closingTime: Scalars['String']['input'];
  day: Scalars['String']['input'];
  isOpen: Scalars['Boolean']['input'];
  openingTime: Scalars['String']['input'];
};

export type WorkingHoursObject = {
  closingTime: Scalars['String']['output'];
  day: Scalars['String']['output'];
  isOpen: Scalars['Boolean']['output'];
  openingTime: Scalars['String']['output'];
};

export type GetEventRequestsCountInput = {
  venueId?: InputMaybe<Scalars['Int']['input']>;
};

export type ListPosts = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderType?: InputMaybe<OrderTypeEnum>;
  searchValue?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<PostsSortTypeEnum>;
  venueId?: InputMaybe<Scalars['Int']['input']>;
};

export type RejectEventReqInput = {
  id: Scalars['Int']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePostInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  hashtagIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  id: Scalars['Int']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  venueId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePublishedStateInput = {
  id: Scalars['Int']['input'];
  isPublished: Scalars['Boolean']['input'];
};

export type IUserFragment = { id: number, name?: string | null, email?: string | null, profile_image?: { key: string, type: string } | null, background_image?: { key: string, type: string } | null };

export type SignUpMutationVariables = Exact<{
  args: CreateUserInput;
}>;


export type SignUpMutation = { createUser: { token: string, user: { id: number, name?: string | null, email?: string | null, profile_image?: { key: string, type: string } | null, background_image?: { key: string, type: string } | null } } };

export type LoginMutationVariables = Exact<{
  userCredentials: UserLoginDto;
}>;


export type LoginMutation = { login: { token: string, user: { id: number, name?: string | null, email?: string | null, profile_image?: { key: string, type: string } | null, background_image?: { key: string, type: string } | null } } };

export type UpdatePasswordMutationVariables = Exact<{
  args: UpdatePasswordInput;
}>;


export type UpdatePasswordMutation = { updatePassword: { token: string, user: { id: number, name?: string | null, email?: string | null, profile_image?: { key: string, type: string } | null, background_image?: { key: string, type: string } | null } } };

export type LoginWithSocialMutationVariables = Exact<{
  userCredentials: LoginWithSocialInput;
}>;


export type LoginWithSocialMutation = { loginWithSocial: { token: string, user: { id: number, name?: string | null, email?: string | null, followers: number, followings: number, online: boolean } } };

export type IBlogFragment = { id: number, title: string, content: string, cover?: string | null, createdAt: any, updatedAt: any, media?: Array<{ id: string, type: string, key: string, sizes?: Array<string> | null }> | null };

export type BlogByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type BlogByIdQuery = { getOneBlogWeb: { id: number, title: string, content: string, cover?: string | null, createdAt: any, updatedAt: any, media?: Array<{ id: string, type: string, key: string, sizes?: Array<string> | null }> | null } };

export type ListBlogsQueryVariables = Exact<{
  filters: ListBlogsInput;
}>;


export type ListBlogsQuery = { listBlogsWeb: { count: number, blogs: Array<{ id: number, title: string, content: string, cover?: string | null, createdAt: any, updatedAt: any, media?: Array<{ id: string, type: string, key: string, sizes?: Array<string> | null }> | null }> } };

export type ITicketFragment = { id: number, name: string, description: string, count: number, soldCount: number, reserveCount: number, amount: number, saleAmount: number, currency: CurrencyEnum, startTime?: any | null, endTime?: any | null, feedId?: number | null, ticketableId?: number | null, ticketableType: TicketableTypeEnum, earlyBirdAmount?: number | null, earlyBirdCount?: number | null, availableEarlyBird: number, media?: Array<{ key: string }> | null };

export type IEventFragment = { id: number, name: string, description?: string | null, startDate: any, endDate: any, address?: string | null, bandName?: string | null, price?: number | null, venueId?: number | null, hasTicket: boolean, isSoldOut: boolean, hasEarlyBirdTickets: boolean, timeZone: string, profitMarginAmount: number, savedByMe: boolean, color?: string | null, media?: Array<{ type: string, key: string, fileName: string, contentLength: number, sizes?: Array<string> | null }> | null, tickets?: Array<{ id: number, name: string, description: string, count: number, soldCount: number, reserveCount: number, amount: number, saleAmount: number, currency: CurrencyEnum, startTime?: any | null, endTime?: any | null, feedId?: number | null, ticketableId?: number | null, ticketableType: TicketableTypeEnum, earlyBirdAmount?: number | null, earlyBirdCount?: number | null, availableEarlyBird: number, media?: Array<{ key: string }> | null }> | null };

export type ITicketPreviewFragment = { amount: number, earlyBirdAmount?: number | null, earlyBirdCount?: number | null, currency: CurrencyEnum };

export type IEventPreviewFragment = { id: number, name: string, startDate: any, endDate: any, address?: string | null, bandName?: string | null, hasEarlyBirdTickets: boolean, timeZone: string, price?: number | null, profitMarginAmount: number, savedByMe: boolean, hasTicket: boolean, isSoldOut: boolean, color?: string | null, media?: Array<{ type: string, key: string, fileName: string, contentLength: number, sizes?: Array<string> | null }> | null, tickets?: Array<{ amount: number, earlyBirdAmount?: number | null, earlyBirdCount?: number | null, currency: CurrencyEnum }> | null };

export type TopEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type TopEventsQuery = { getLast20Events: Array<{ id: number, name: string, startDate: any, endDate: any, address?: string | null, bandName?: string | null, hasEarlyBirdTickets: boolean, timeZone: string, price?: number | null, profitMarginAmount: number, savedByMe: boolean, hasTicket: boolean, isSoldOut: boolean, color?: string | null, media?: Array<{ type: string, key: string, fileName: string, contentLength: number, sizes?: Array<string> | null }> | null, tickets?: Array<{ amount: number, earlyBirdAmount?: number | null, earlyBirdCount?: number | null, currency: CurrencyEnum }> | null }> };

export type AllEventsQueryVariables = Exact<{
  filters: ListEventsWebInput;
}>;


export type AllEventsQuery = { listEventsWeb: { count: number, events: Array<{ id: number, name: string, description?: string | null, startDate: any, endDate: any, address?: string | null, bandName?: string | null, price?: number | null, venueId?: number | null, hasTicket: boolean, isSoldOut: boolean, hasEarlyBirdTickets: boolean, timeZone: string, profitMarginAmount: number, savedByMe: boolean, color?: string | null, media?: Array<{ type: string, key: string, fileName: string, contentLength: number, sizes?: Array<string> | null }> | null, tickets?: Array<{ id: number, name: string, description: string, count: number, soldCount: number, reserveCount: number, amount: number, saleAmount: number, currency: CurrencyEnum, startTime?: any | null, endTime?: any | null, feedId?: number | null, ticketableId?: number | null, ticketableType: TicketableTypeEnum, earlyBirdAmount?: number | null, earlyBirdCount?: number | null, availableEarlyBird: number, media?: Array<{ key: string }> | null }> | null }> } };

export type GetEventByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetEventByIdQuery = { findEventWeb: { id: number, name: string, description?: string | null, startDate: any, endDate: any, address?: string | null, bandName?: string | null, price?: number | null, venueId?: number | null, hasTicket: boolean, isSoldOut: boolean, hasEarlyBirdTickets: boolean, timeZone: string, profitMarginAmount: number, savedByMe: boolean, color?: string | null, media?: Array<{ type: string, key: string, fileName: string, contentLength: number, sizes?: Array<string> | null }> | null, tickets?: Array<{ id: number, name: string, description: string, count: number, soldCount: number, reserveCount: number, amount: number, saleAmount: number, currency: CurrencyEnum, startTime?: any | null, endTime?: any | null, feedId?: number | null, ticketableId?: number | null, ticketableType: TicketableTypeEnum, earlyBirdAmount?: number | null, earlyBirdCount?: number | null, availableEarlyBird: number, media?: Array<{ key: string }> | null }> | null } };

export type GetFavoriteEventsQueryVariables = Exact<{
  filters: ListFavoritesByTypeInput;
}>;


export type GetFavoriteEventsQuery = { listFavoriteEvents: { count: number, events: Array<{ id: number, name: string, startDate: any, endDate: any, address?: string | null, bandName?: string | null, hasEarlyBirdTickets: boolean, timeZone: string, price?: number | null, profitMarginAmount: number, savedByMe: boolean, hasTicket: boolean, isSoldOut: boolean, color?: string | null, media?: Array<{ type: string, key: string, fileName: string, contentLength: number, sizes?: Array<string> | null }> | null, tickets?: Array<{ amount: number, earlyBirdAmount?: number | null, earlyBirdCount?: number | null, currency: CurrencyEnum }> | null }> } };

export type TicketByEventIdQueryVariables = Exact<{
  eventId: Scalars['Int']['input'];
}>;


export type TicketByEventIdQuery = { findTicketByEventId: Array<{ id: number, name: string, description: string, count: number, soldCount: number, reserveCount: number, amount: number, saleAmount: number, currency: CurrencyEnum, startTime?: any | null, endTime?: any | null, feedId?: number | null, ticketableId?: number | null, ticketableType: TicketableTypeEnum, earlyBirdAmount?: number | null, earlyBirdCount?: number | null, availableEarlyBird: number, media?: Array<{ key: string }> | null }> };

export type GetDiscountQueryVariables = Exact<{
  code: Scalars['String']['input'];
  venueId: Scalars['Int']['input'];
}>;


export type GetDiscountQuery = { findDiscountByCodeAndVenueId: { id: number, name: string, discount: number, type: DiscountTypeEnum } };

export type CancelCheckoutSessionMutationVariables = Exact<{
  args: CancelCheckoutSessionInput;
}>;


export type CancelCheckoutSessionMutation = { cancelCheckoutSession: { status: number, message: string } };

export type BuyTicketMutationVariables = Exact<{
  args: OrderTicketInputWeb;
}>;


export type BuyTicketMutation = { orderTicketWeb: { clientSecret?: string | null, note?: { earlyBirdAvailableCount: number, currency: string, amount: number, boughtEarlyBirdQuantity: number } | null } };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { getMe: { id: number, name?: string | null, email?: string | null, profile_image?: { key: string, type: string } | null, background_image?: { key: string, type: string } | null } };

export type UpdateUserMutationVariables = Exact<{
  args: UpdateUserInput;
}>;


export type UpdateUserMutation = { updateUser: { id: number, name?: string | null, email?: string | null, profile_image?: { key: string, type: string } | null, background_image?: { key: string, type: string } | null } };

export type RequestToBecomeManagerMutationVariables = Exact<{
  args: CreateAdminRequestInput;
}>;


export type RequestToBecomeManagerMutation = { createAdminRequest: { status: number, message: string } };

export type GetPresignedUrlMutationVariables = Exact<{
  getPreSignedUrlInput: GetPreSignedUrlInput;
}>;


export type GetPresignedUrlMutation = { getPresignedUrl: { url: string, key: string } };

export type LikeMutationVariables = Exact<{
  args: MakeFavoriteInput;
}>;


export type LikeMutation = { makeFavorite: { id: number } };

export type UnlikeMutationVariables = Exact<{
  args: DeleteInput;
}>;


export type UnlikeMutation = { deleteFromFavorite: { message: string } };

export type SubscribeMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type SubscribeMutation = { createSubscriber: { status: number, message: string } };

export type ContactUsMutationVariables = Exact<{
  args: CreateContactInfoInput;
}>;


export type ContactUsMutation = { createContactInfo: { status: number, message: string } };

export type IVenueFragment = { id: number, name: string, description?: string | null, capacity?: string | null, internationalPhoneNumber?: string | null, address: string, logo?: string | null, categoryId?: number | null, googlePlaceId?: string | null, totalComments: number, avgRate: number, ownRate?: number | null, cameraNumber: number, savedByMe: boolean, linkToWebsite?: string | null, linkToFaceBook?: string | null, linkToYoutube?: string | null, linkToInstagram?: string | null, featuredImageUrl?: { type: string, key: string, sizes?: Array<string> | null } | null, workingHours: Array<{ day: string, openingTime: string, closingTime: string }>, media?: Array<{ type: string, key: string, sizes?: Array<string> | null }> | null, location?: { coordinates: Array<string> } | null };

export type IVenuePreviewFragment = { id: number, name: string, savedByMe: boolean, categoryId?: number | null, avgRate: number, featuredImageUrl?: { type: string, key: string, sizes?: Array<string> | null } | null, media?: Array<{ type: string, key: string, sizes?: Array<string> | null }> | null };

export type BestVenuesQueryVariables = Exact<{ [key: string]: never; }>;


export type BestVenuesQuery = { getBestVenues: Array<{ id: number, name: string, savedByMe: boolean, categoryId?: number | null, avgRate: number, featuredImageUrl?: { type: string, key: string, sizes?: Array<string> | null } | null, media?: Array<{ type: string, key: string, sizes?: Array<string> | null }> | null }> };

export type ICategoryFragment = { id: number, name: string, slug: string };

export type AllCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllCategoriesQuery = { getAllCategories: Array<{ id: number, name: string, slug: string }> };

export type AllVenuesQueryVariables = Exact<{
  filters: ListVenuesWebInput;
}>;


export type AllVenuesQuery = { listVenuesWeb: { count: number, venues: Array<{ id: number, name: string, savedByMe: boolean, categoryId?: number | null, avgRate: number, featuredImageUrl?: { type: string, key: string, sizes?: Array<string> | null } | null, media?: Array<{ type: string, key: string, sizes?: Array<string> | null }> | null }> } };

export type AllVenueNamesQueryVariables = Exact<{
  filters: ListVenuesWebInput;
}>;


export type AllVenueNamesQuery = { listVenuesWeb: { count: number, venues: Array<{ id: number, name: string }> } };

export type IVenueListFragment = { id: number, name: string, address: string };

export type AllVenuesPreviewQueryVariables = Exact<{
  filters: ListVenuesWebInput;
}>;


export type AllVenuesPreviewQuery = { listVenuesWeb: { count: number, venues: Array<{ id: number, name: string, address: string }> } };

export type GetFavoriteVenuesQueryVariables = Exact<{
  filters: ListFavoritesByTypeInput;
}>;


export type GetFavoriteVenuesQuery = { listFavoriteVenues: { count: number, venues: Array<{ id: number, name: string, savedByMe: boolean, categoryId?: number | null, avgRate: number, featuredImageUrl?: { type: string, key: string, sizes?: Array<string> | null } | null, media?: Array<{ type: string, key: string, sizes?: Array<string> | null }> | null }> } };

export type GetVenueByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetVenueByIdQuery = { getVenueWeb: { id: number, name: string, description?: string | null, capacity?: string | null, internationalPhoneNumber?: string | null, address: string, logo?: string | null, categoryId?: number | null, googlePlaceId?: string | null, totalComments: number, avgRate: number, ownRate?: number | null, cameraNumber: number, savedByMe: boolean, linkToWebsite?: string | null, linkToFaceBook?: string | null, linkToYoutube?: string | null, linkToInstagram?: string | null, featuredImageUrl?: { type: string, key: string, sizes?: Array<string> | null } | null, workingHours: Array<{ day: string, openingTime: string, closingTime: string }>, media?: Array<{ type: string, key: string, sizes?: Array<string> | null }> | null, location?: { coordinates: Array<string> } | null } };

export type RateMutationVariables = Exact<{
  args: CreateVenueRate;
}>;


export type RateMutation = { createVenueRate: { id: number, rate: number } };

export type VenueCommentMutationVariables = Exact<{
  args: CreateVenueComment;
}>;


export type VenueCommentMutation = { createVenueComment: { id: number, comment: string, likeCount: number, replyCount: number, createdAt: any, updatedAt: any, parent?: { id: number, comment: string, likeCount: number, replyCount: number, createdAt: any, updatedAt: any } | null, user: { id: number, name?: string | null, email?: string | null, profile_image?: { key: string, type: string } | null } } };

export type GetVenueCommentsQueryVariables = Exact<{
  filters: GetVenueComments;
}>;


export type GetVenueCommentsQuery = { getVenueComments: Array<{ id: number, comment: string, likeCount: number, replyCount: number, createdAt: any, updatedAt: any, parent?: { id: number, comment: string, likeCount: number, replyCount: number, createdAt: any, updatedAt: any } | null, user: { id: number, name?: string | null, email?: string | null, profile_image?: { key: string, type: string } | null } }> };

export type GetVenueRatesQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetVenueRatesQuery = { getVenueRatesCountWeb: { ratesTotalCount: number, venueRatesGrouped: Array<{ rate: number, rateGroupCount?: number | null }> } };
