import React, { memo, useRef } from 'react';
import styles from './HomeBummer.module.scss';
import { createAmazonImageUrl } from '../../../utils/image';

const HomeBanner = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  // const imageRef = useRef<HTMLImageElement>(null);

  // const onMousemove = useCallback((ev: MouseEvent) => {
  //   ev.preventDefault();
  //
  //   if (imageRef.current) {
  //     const maxX = window.innerWidth - 100; // Adjust as needed
  //     // Adjust as needed
  //     const x = (ev.clientX / maxX) * (400 + 400) - 400; // Interpolate between -400 and 400
  //     const deg = (ev.clientX / maxX) * (225 - 135) + 135; // Interpolate between 135 and 225
  //     imageRef.current.style.transform = `translate3d(${x || 0}px, 0, 0) rotateY(${deg}deg)`;
  //   }
  // }, []);

  // const onScroll = useCallback(() => {
  //   if (imageRef.current) {
  //     imageRef.current.style.transform = `translate3d(0, 0, 0) rotateY(0deg)`;
  //   }
  // }, []);

  // useEffect(() => {
  //   if (ref.current) {
  //     ref.current?.addEventListener('mousemove', onMousemove);
  //   }
  //
  //   return () => {
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //     ref.current?.removeEventListener('mousemove', onMousemove);
  //   };
  // }, [onMousemove]);

  return (
    <div ref={ref} className={styles.homeBanner}>
      <video className={styles.homeBanner_video} autoPlay muted loop preload="none" poster={createAmazonImageUrl('common/banner_placeholder.png')}>
        <source
          type="video/mp4"
          src={createAmazonImageUrl('common/Banner.mov')}
        />
      </video>
    </div>
  );
};

export default memo(HomeBanner);
