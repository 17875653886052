import React, { createContext, Dispatch, FC, memo, useContext, useEffect, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useGetEventByIdQuery } from '../api/query/event';
import { useBuyTicketMutation, useTicketByEventIdQuery } from '../api/query/ticket';
import {
  BuyTicketMutation,
  Exact,
  IEventFragment,
  ITicketFragment,
  Maybe,
  OrderTicketInputWeb,
} from '../api/query/types';
import useReducer from '../hooks/useReducer';
import { Action, Discount } from '../models/base';

dayjs.extend(utc);
dayjs.extend(timezone);
export const LOCAL_TIME_ZONE = dayjs.tz.guess();

export enum TicketActionType {
  SET_COUNT = 'SET_COUNT',
  SET_PROMO_CODE = 'SET_PROMO_CODE',
  SET_SELECTED_TICKET_ID = 'SET_SELECTED_TICKET_ID',
  SET_DISCOUNT = 'SET_DISCOUNT',
  CLEAN = 'CLEAN',
}

export interface TicketState {
  count: number;
  promoCode: string;
  selectedTicketId: Maybe<number>;
  discount: Maybe<Discount>;
}

interface EventState {
  event: Maybe<IEventFragment>;
  isLoading: boolean;
  tickets?: Maybe<Array<ITicketFragment>>;
  timeZoneText?: string;
  ticketState: TicketState;
  dispatch: Dispatch<Action<TicketActionType>>;
  getClientSecret?: UseMutateAsyncFunction<
    BuyTicketMutation,
    unknown,
    Exact<{
      args: OrderTicketInputWeb;
    }>,
    unknown
  >;
}

const initialState: TicketState = {
  count: 1,
  promoCode: '',
  selectedTicketId: null,
  discount: null,
};

const EventContext = createContext<EventState>({
  event: null,
  isLoading: false,
  tickets: null,
  ticketState: initialState,
  dispatch: () => {},
});

export const EventProvider: FC = memo(() => {
  const { eventId } = useParams();
  const [state, dispatch] = useReducer<TicketState, TicketActionType>(
    'ticket',
    reducer,
    initialState,
    true,
  );

  const { data: event, isLoading: eventLoading } = useGetEventByIdQuery(
    { id: Number(eventId) },
    { refetchOnWindowFocus: false },
  );

  const { data: ticketsData, isLoading: ticketLoading } = useTicketByEventIdQuery(
    { eventId: Number(eventId) },
    { refetchOnWindowFocus: false },
  );

  const { mutateAsync: getClientSecret, isLoading: buyLoading } = useBuyTicketMutation();

  useEffect(() => {
    if (ticketsData?.findTicketByEventId) {
      dispatch({
        type: TicketActionType.SET_SELECTED_TICKET_ID,
        payload: ticketsData.findTicketByEventId[0].id,
      });
    }
  }, [ticketsData?.findTicketByEventId]);

  const timeZoneText = useMemo(
    () =>
      event?.findEventWeb?.timeZone !== LOCAL_TIME_ZONE
        ? event?.findEventWeb?.timeZone.split('/')[1]
        : '',
    [event?.findEventWeb?.timeZone],
  );

  return (
    <EventContext.Provider
      value={{
        ticketState: state,
        dispatch,
        event: event?.findEventWeb || null,
        isLoading: eventLoading || ticketLoading || buyLoading,
        tickets: ticketsData?.findTicketByEventId,
        timeZoneText,
        getClientSecret,
      }}>
      <Outlet />
    </EventContext.Provider>
  );
});

export const useEvent = () => useContext(EventContext);

const reducer = (state: TicketState, action: Action<TicketActionType>): TicketState => {
  switch (action.type) {
    case TicketActionType.SET_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    case TicketActionType.SET_PROMO_CODE:
      return {
        ...state,
        promoCode: action.payload,
      };

    case TicketActionType.SET_SELECTED_TICKET_ID:
      return {
        ...state,
        selectedTicketId: action.payload,
      };

    case TicketActionType.SET_DISCOUNT:
      return {
        ...state,
        discount: action.payload,
      };
    case TicketActionType.CLEAN: {
      return initialState;
    }
    default:
      return state;
  }
};
