import { Texts } from './texts';
import { SelectItem } from '../../models/base';

export const djColors = [
  'rgba(255, 0, 195, 0.1)',
  'rgba(128, 255, 0, 0.1)',
  'rgba(0, 10, 255, 0.1)',
  'rgba(0, 255, 255, 0.1)',
  'rgba(255, 107, 0, 0.1)',
];

export const bottomLinks = [
  {
    title: Texts.ABOUT_US,
    to: '/about-us',
  },
  { title: Texts.EVENTS, to: '/events' },
  {
    title: Texts.TERM_CONDITION,
    to: '/term-and-condition',
  },
  {
    title: Texts.CONTACT_US,
    to: '/contact-us',
  },
  { title: Texts.BLOG, to: '/blog' },
  { title: Texts.VENUES, to: '/venues' },
  { title: Texts.PRIVACY_POLICY, to: '/privacy_policy' },
  {
    title: Texts.FAQ,
    to: '/FAQ',
  },
  {
    title: Texts.DOWNLOAD,
    to: '/download',
  },
];

export const filters: Array<SelectItem> = [
  { id: 0, value: Texts.COFFEE },
  { id: 1, value: Texts.NIGHT_CLUB },
  { id: 2, value: Texts.PUB },
  { id: 3, value: Texts.OTHERS },
];
