import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './Header.module.scss';
import { Texts } from '../../../assets/constants/texts';
import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';
import { AuthActionType, useAuthState } from '../../../contexts/AuthProvider';
import { useOAuth } from '../../../hooks/useOAuth';
import { Button, Link } from '../../atom';

const MobileHeader = () => {
  const [opened, setOpened] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    state: { isLoggedIn },
    dispatch,
  } = useAuthState();
  const { signOut } = useOAuth();

  const handleLogout = useCallback(() => {
    signOut();
    dispatch({ type: AuthActionType.LOG_OUT });
  }, [dispatch, signOut]);

  return (
    <nav className={[styles.mobile_header, opened && styles.mobile_header_opened].join(' ')}>
      <div className={styles.navigation}>
        <div
          className={[styles.hamburger, opened && styles.hamburger_opened].join(' ')}
          onClick={() => setOpened(prev => !prev)}>
          <span />
          <span />
          <span />
        </div>
        <Link to="/" onClick={() => setOpened(false)} className={styles.logo}>
          <Logo height={42} />
        </Link>
      </div>

      <div className={styles.menus}>
        <div className={styles.left}>
          <Link
            to="/events"
            onClick={() => setOpened(false)}
            isActive={pathname.includes('events')}>
            {Texts.EVENTS}
          </Link>
          <Link
            to="/venues"
            onClick={() => setOpened(false)}
            isActive={pathname.includes('venues')}>
            {Texts.VENUES}
          </Link>
          <Link
            to="/about-us"
            onClick={() => setOpened(false)}
            isActive={pathname.includes('about-us')}>
            {Texts.ABOUT_US}
          </Link>
          <Link to="/blog" onClick={() => setOpened(false)} isActive={pathname.includes('blog')}>
            {Texts.BLOG}
          </Link>
          <Button
            buttonType="positive"
            text={Texts.GET_STARTED}
            onClick={() => {
              setOpened(false);
              navigate('/get-started');
            }}
          />
        </div>
        <div className={styles.right}>
          {isLoggedIn ? (
            <>
              <Link to="/favorites" onClick={() => setOpened(false)}>
                {Texts.FAVORITES}
              </Link>
              <Link to="/profile" onClick={() => setOpened(false)}>
                {Texts.header.profile.menu_1}
              </Link>
              <Button
                buttonType="negative"
                onClick={() => {
                  setOpened(false);
                  handleLogout();
                }}
                text={Texts.header.profile.menu_2}
              />
            </>
          ) : (
            <Button
              buttonType="negative"
              onClick={() => {
                navigate('/login'), setOpened(false);
              }}
              text={Texts.LOGIN}
            />
          )}
        </div>
      </div>
    </nav>
  );
};

export default MobileHeader;
