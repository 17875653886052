import React, { FC, memo } from 'react';

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  fallbackImage: React.ImgHTMLAttributes<HTMLImageElement>['src'];
}

const Image: FC<ImageProps> = ({ src, fallbackImage, ...props }) => {
  const onImageError = (event: any) => {
    event.target.src = fallbackImage;
  };

  return (
    <img src={src || fallbackImage} onError={onImageError} {...props} />
  );
};

export default memo(Image);