import React, { forwardRef, HTMLProps, useMemo } from 'react';
import { ReactNode } from 'react';
import style from './TextArea.module.scss';
import Text from '../Text/Text';

interface TextInputProps {
  helperText?: string;
  helperTextColor?: string;
  baseClassName?: string;
  inputClassName?: string;
  inputLeftSide?: ReactNode;
  inputRightSide?: ReactNode;
  registerValidation?: object;
  errorText?: string;
  placeholderColor?: string;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextInputProps & HTMLProps<HTMLTextAreaElement>>(
  ({ className, ...props }, ref) => {
    const inputStyle = useMemo(() => {
      let initial = style.textAreaContainer_textArea;

      if (props.disabled) {
        initial = [initial, style.textAreaContainer_textArea_disabled].join(' ');
      }

      return [initial, className].join(' ');
    }, [className, props.disabled]);

    const containerStyle = props.disabled
      ? [style.textAreaContainer, style.textAreaContainer_disabled].join(' ')
      : style.textAreaContainer;

    return (
      <div className={[style.base, props.baseClassName].join(' ')}>
        <span className={containerStyle}>
          {props.helperText ? (
            <Text style={{ color: props.helperTextColor }} className={style.helperText}>
              {props.helperText}
            </Text>
          ) : null}
          <div
            className={[style.textAreaContainer_textAreaContainer, props.inputClassName].join(' ')}>
            {props.inputLeftSide ? (
              <div className={style.leftSide}>{props.inputLeftSide}</div>
            ) : null}

            <textarea
              ref={ref}
              className={
                props.disabled
                  ? [inputStyle, style.textAreaContainer_textArea_disabled].join(' ')
                  : inputStyle
              }
              onClick={e => e.preventDefault()}
              defaultValue={props.disabled ? undefined : props.defaultValue}
              {...props.registerValidation}
              {...props}
            />
            {props.inputRightSide || null}
          </div>
        </span>
        {props.errorText && <Text className={style.error}>{props.errorText}</Text>}
      </div>
    );
  },
);

export default TextArea;
