import React, { forwardRef, InputHTMLAttributes, useMemo } from 'react';
import { ReactNode, useState } from 'react';
import style from './Input.module.scss';
import { ReactComponent as ShowIcon } from '../../../assets/icons/eye.svg';
import { ReactComponent as HideIcon } from '../../../assets/icons/eyeHide.svg';
import Text from '../Text/Text';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  helperText?: string;
  helperTextColor?: string;
  baseClassName?: string;
  inputClassName?: string;
  inputLeftSide?: ReactNode;
  inputRightSide?: ReactNode;
  registerValidation?: any;
  errorText?: string | null;
  children?: any;
  // placeholderColor?: string;
}

const Input = forwardRef<HTMLInputElement, TextInputProps>(
  ({ type = 'text', className, ...props }, ref) => {
    const [inputType, setInputType] = useState(type);
    const [focused, setFocused] = useState(false);

    const inputStyle = useMemo(() => {
      let initial = style.textInputContainer_input;
      if (type === 'password') {
        initial = [initial, style.textInputContainer_boldInput].join(' ');
      }
      if (props.disabled) {
        initial = [initial, style.textInputContainer_input_disabled].join(' ');
      }

      return [initial, className].join(' ');
    }, [className, props.disabled, type]);

    const containerStyle = props.disabled
      ? [style.textInputContainer, style.textInputContainer_disabled].join(' ')
      : style.textInputContainer;

    return (
      <div className={[style.base, props.baseClassName].join(' ')}>
        <span className={containerStyle}>
          {props.helperText ? (
            <Text style={{ color: props.helperTextColor }} className={style.helperText}>
              {props.helperText}
            </Text>
          ) : null}
          <div
            style={{ border: focused ? '0.5px solid #E63335' : '0.5px solid transparent' }}
            className={[style.inputContainer, props.inputClassName].join(' ')}>
            {props.inputLeftSide ? (
              <div className={style.leftSide}>{props.inputLeftSide}</div>
            ) : null}

            <input
              ref={ref}
              onFocus={ev => {
                props.onFocus && props.onFocus(ev);
                props.registerValidation?.onFocus && props.registerValidation?.onFocus(ev);
                setFocused(true);
              }}
              className={
                props.disabled
                  ? [inputStyle, style.textInputContainer_input_disabled].join(' ')
                  : inputStyle
              }
              onClick={e => e.preventDefault()}
              defaultValue={props.disabled ? undefined : props.defaultValue}
              {...props.registerValidation}
              {...props}
              onBlur={ev => {
                setFocused(false);
                props.onBlur && props.onBlur(ev);
                props.registerValidation?.onBlur && props.registerValidation?.onBlur(ev);
              }}
              type={inputType}
            />
            {type === 'password' ? (
              <div className={style.textInputContainer_iconContainer}>
                {inputType === 'password' ? (
                  <HideIcon
                    onClick={() => {
                      setInputType('text');
                    }}
                  />
                ) : (
                  <ShowIcon
                    onClick={() => {
                      setInputType('password');
                    }}
                  />
                )}
              </div>
            ) : null}
            {props.inputRightSide || null}
          </div>
        </span>
        {props.errorText && <Text className={style.error}>{props.errorText}</Text>}
      </div>
    );
  },
);

export default Input;
