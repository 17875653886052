/* eslint-disable unused-imports/no-unused-imports */

import { useCallback, useState } from 'react';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import {
  FacebookAuthProvider,
  getAdditionalUserInfo,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { getAuth } from 'firebase/auth';
import { useLoginWithSocialMutation } from '../api/query/auth';
import { AuthProviderEnum } from '../api/query/types';
import { AuthActionType, useAuthState } from '../contexts/AuthProvider';

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

initializeApp(firebaseConfig);

const auth = getAuth();

export const useOAuth = () => {
  const { dispatch, setToken } = useAuthState();
  const { mutate, isLoading } = useLoginWithSocialMutation({
    onSuccess: data => {
      dispatch({ type: AuthActionType.LOG_IN });
      setToken(data.loginWithSocial.token);
    },

    onError: (err: any) => {
      console.log({ err });
    },
  });

  const signUp = useCallback(
    async (provider: any) => {
      try {
        await signInWithPopup(auth, provider).then(async result => {
          const token = await result.user.getIdToken();
          const user = getAdditionalUserInfo(result)?.profile;

          if (token) {
            mutate({
              userCredentials: {
                token,
                email: user?.email as string,
                name: user?.name as string,
                provider: AuthProviderEnum.Google,
              },
            });
          }
        });
      } catch (err) {
        console.warn(err);
      }
    },
    [mutate],
  );

  const signInGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('email');
    await signUp(provider);
  }, [signUp]);

  const signOutOAuth = useCallback(async () => {
    await signOut(auth);
  }, []);

  const signInFacebook = useCallback(async () => {
    const provider = new FacebookAuthProvider();
    await signUp(provider);
  }, [signUp]);

  return { signInGoogle, signOut: signOutOAuth, isLoading, signInFacebook };
};
