import React, { FC, HTMLAttributes, memo, useCallback, useState } from 'react';
import styles from './Checkbox.module.scss';
import { ReactComponent as Unchecked } from '../../../assets/icons/checkbox.svg';
import { ReactComponent as Checked } from '../../../assets/icons/checked.svg';

interface Props extends HTMLAttributes<HTMLDivElement> {
  initialValue?: boolean;
  onValueChange?: (value: boolean) => void;
}

const Checkbox: FC<Props> = ({ initialValue = false, onValueChange, ...rest }) => {
  const [state, setState] = useState(initialValue);

  const onChange = useCallback(() => {
    onValueChange && onValueChange(!state);
    setState(prev => !prev);
  }, [onValueChange, state]);

  return (
    <div {...rest} className={[styles.checkbox, rest.className].join(' ')} onClick={onChange}>
      {state ? <Checked /> : <Unchecked />}
      {rest.children}
    </div>
  );
};

export default memo(Checkbox);
