import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Loading } from '../components/atom';
import { Layout } from '../components/organism';

export const PublicLayout = () => {
  return (
    <Layout>
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </Layout>
  );
};
