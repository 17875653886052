import React from 'react';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './contexts/AuthProvider';
import { InitialDataProvider } from './contexts/InitialDataProvider';
import { ProfileProvider } from './contexts/ProfileProvider';
import { useScrollToTop } from './hooks/useScrollToTop';
import Routes from './routes';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  useScrollToTop();
  return (
    <div>
      <AuthProvider>
        <ProfileProvider>
          <InitialDataProvider>
            <Routes />
          </InitialDataProvider>
        </ProfileProvider>
      </AuthProvider>
      <ToastContainer
        position="bottom-right"
        pauseOnHover
        pauseOnFocusLoss
        rtl={false}
        closeOnClick
        newestOnTop={false}
        autoClose={3500}
      />
    </div>
  );
}

export default App;
