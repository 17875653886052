import React, { createContext, FC, useContext } from 'react';
import { Category } from '../api/query/types';
import { useAllCategoriesQuery } from '../api/query/venue';

type ICategory = Pick<Category, 'id' | 'name' | 'slug'>;

interface InitialData {
  categories?: Array<ICategory>;
}

const InitialDataContext = createContext<InitialData>({});

interface Props {
  children?: JSX.Element;
}

export const InitialDataProvider: FC<Props> = ({ children }) => {
  const { data } = useAllCategoriesQuery(undefined, { refetchOnWindowFocus: false });

  return (
    <InitialDataContext.Provider value={{ categories: data?.getAllCategories }}>
      {children}
    </InitialDataContext.Provider>
  );
};

export const useInitialData = () => useContext(InitialDataContext);
