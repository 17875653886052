import * as Types from './types';

import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { axiosRequest } from '../axiosHelper';
export const IVenueFragmentDoc = `
    fragment IVenue on Venue {
  id
  name
  description
  capacity
  featuredImageUrl {
    type
    key
    sizes
  }
  internationalPhoneNumber
  address
  workingHours {
    day
    openingTime
    closingTime
  }
  media {
    type
    key
    sizes
  }
  logo
  categoryId
  location {
    coordinates
  }
  googlePlaceId
  totalComments
  avgRate
  ownRate
  cameraNumber
  savedByMe
  linkToWebsite
  linkToFaceBook
  linkToYoutube
  linkToInstagram
}
    `;
export const IVenuePreviewFragmentDoc = `
    fragment IVenuePreview on Venue {
  id
  name
  savedByMe
  featuredImageUrl {
    type
    key
    sizes
  }
  media {
    type
    key
    sizes
  }
  categoryId
  avgRate
}
    `;
export const ICategoryFragmentDoc = `
    fragment ICategory on Category {
  id
  name
  slug
}
    `;
export const IVenueListFragmentDoc = `
    fragment IVenueList on Venue {
  id
  name
  address
}
    `;
export const BestVenuesDocument = `
    query bestVenues {
  getBestVenues {
    ...IVenuePreview
  }
}
    ${IVenuePreviewFragmentDoc}`;
export const useBestVenuesQuery = <
      TData = Types.BestVenuesQuery,
      TError = unknown
    >(
      variables?: Types.BestVenuesQueryVariables,
      options?: UseQueryOptions<Types.BestVenuesQuery, TError, TData>
    ) =>
    useQuery<Types.BestVenuesQuery, TError, TData>(
      variables === undefined ? ['bestVenues'] : ['bestVenues', variables],
      axiosRequest<Types.BestVenuesQuery, Types.BestVenuesQueryVariables>(BestVenuesDocument).bind(null, variables),
      options
    );
useBestVenuesQuery.document = BestVenuesDocument;


useBestVenuesQuery.getKey = (variables?: Types.BestVenuesQueryVariables) => variables === undefined ? ['bestVenues'] : ['bestVenues', variables];
;

export const AllCategoriesDocument = `
    query allCategories {
  getAllCategories {
    ...ICategory
  }
}
    ${ICategoryFragmentDoc}`;
export const useAllCategoriesQuery = <
      TData = Types.AllCategoriesQuery,
      TError = unknown
    >(
      variables?: Types.AllCategoriesQueryVariables,
      options?: UseQueryOptions<Types.AllCategoriesQuery, TError, TData>
    ) =>
    useQuery<Types.AllCategoriesQuery, TError, TData>(
      variables === undefined ? ['allCategories'] : ['allCategories', variables],
      axiosRequest<Types.AllCategoriesQuery, Types.AllCategoriesQueryVariables>(AllCategoriesDocument).bind(null, variables),
      options
    );
useAllCategoriesQuery.document = AllCategoriesDocument;


useAllCategoriesQuery.getKey = (variables?: Types.AllCategoriesQueryVariables) => variables === undefined ? ['allCategories'] : ['allCategories', variables];
;

export const AllVenuesDocument = `
    query allVenues($filters: ListVenuesWebInput!) {
  listVenuesWeb(listVenueWebArgs: $filters) {
    venues {
      ...IVenuePreview
    }
    count
  }
}
    ${IVenuePreviewFragmentDoc}`;
export const useAllVenuesQuery = <
      TData = Types.AllVenuesQuery,
      TError = unknown
    >(
      variables: Types.AllVenuesQueryVariables,
      options?: UseQueryOptions<Types.AllVenuesQuery, TError, TData>
    ) =>
    useQuery<Types.AllVenuesQuery, TError, TData>(
      ['allVenues', variables],
      axiosRequest<Types.AllVenuesQuery, Types.AllVenuesQueryVariables>(AllVenuesDocument).bind(null, variables),
      options
    );
useAllVenuesQuery.document = AllVenuesDocument;


useAllVenuesQuery.getKey = (variables: Types.AllVenuesQueryVariables) => ['allVenues', variables];
;

export const AllVenueNamesDocument = `
    query allVenueNames($filters: ListVenuesWebInput!) {
  listVenuesWeb(listVenueWebArgs: $filters) {
    venues {
      id
      name
    }
    count
  }
}
    `;
export const useAllVenueNamesQuery = <
      TData = Types.AllVenueNamesQuery,
      TError = unknown
    >(
      variables: Types.AllVenueNamesQueryVariables,
      options?: UseQueryOptions<Types.AllVenueNamesQuery, TError, TData>
    ) =>
    useQuery<Types.AllVenueNamesQuery, TError, TData>(
      ['allVenueNames', variables],
      axiosRequest<Types.AllVenueNamesQuery, Types.AllVenueNamesQueryVariables>(AllVenueNamesDocument).bind(null, variables),
      options
    );
useAllVenueNamesQuery.document = AllVenueNamesDocument;


useAllVenueNamesQuery.getKey = (variables: Types.AllVenueNamesQueryVariables) => ['allVenueNames', variables];
;

export const AllVenuesPreviewDocument = `
    query allVenuesPreview($filters: ListVenuesWebInput!) {
  listVenuesWeb(listVenueWebArgs: $filters) {
    venues {
      ...IVenueList
    }
    count
  }
}
    ${IVenueListFragmentDoc}`;
export const useAllVenuesPreviewQuery = <
      TData = Types.AllVenuesPreviewQuery,
      TError = unknown
    >(
      variables: Types.AllVenuesPreviewQueryVariables,
      options?: UseQueryOptions<Types.AllVenuesPreviewQuery, TError, TData>
    ) =>
    useQuery<Types.AllVenuesPreviewQuery, TError, TData>(
      ['allVenuesPreview', variables],
      axiosRequest<Types.AllVenuesPreviewQuery, Types.AllVenuesPreviewQueryVariables>(AllVenuesPreviewDocument).bind(null, variables),
      options
    );
useAllVenuesPreviewQuery.document = AllVenuesPreviewDocument;


useAllVenuesPreviewQuery.getKey = (variables: Types.AllVenuesPreviewQueryVariables) => ['allVenuesPreview', variables];
;

export const GetFavoriteVenuesDocument = `
    query getFavoriteVenues($filters: ListFavoritesByTypeInput!) {
  listFavoriteVenues(listFavoritesByTypeInput: $filters) {
    count
    venues {
      ...IVenuePreview
    }
  }
}
    ${IVenuePreviewFragmentDoc}`;
export const useGetFavoriteVenuesQuery = <
      TData = Types.GetFavoriteVenuesQuery,
      TError = unknown
    >(
      variables: Types.GetFavoriteVenuesQueryVariables,
      options?: UseQueryOptions<Types.GetFavoriteVenuesQuery, TError, TData>
    ) =>
    useQuery<Types.GetFavoriteVenuesQuery, TError, TData>(
      ['getFavoriteVenues', variables],
      axiosRequest<Types.GetFavoriteVenuesQuery, Types.GetFavoriteVenuesQueryVariables>(GetFavoriteVenuesDocument).bind(null, variables),
      options
    );
useGetFavoriteVenuesQuery.document = GetFavoriteVenuesDocument;


useGetFavoriteVenuesQuery.getKey = (variables: Types.GetFavoriteVenuesQueryVariables) => ['getFavoriteVenues', variables];
;

export const GetVenueByIdDocument = `
    query getVenueById($id: Int!) {
  getVenueWeb(id: $id) {
    ...IVenue
  }
}
    ${IVenueFragmentDoc}`;
export const useGetVenueByIdQuery = <
      TData = Types.GetVenueByIdQuery,
      TError = unknown
    >(
      variables: Types.GetVenueByIdQueryVariables,
      options?: UseQueryOptions<Types.GetVenueByIdQuery, TError, TData>
    ) =>
    useQuery<Types.GetVenueByIdQuery, TError, TData>(
      ['getVenueById', variables],
      axiosRequest<Types.GetVenueByIdQuery, Types.GetVenueByIdQueryVariables>(GetVenueByIdDocument).bind(null, variables),
      options
    );
useGetVenueByIdQuery.document = GetVenueByIdDocument;


useGetVenueByIdQuery.getKey = (variables: Types.GetVenueByIdQueryVariables) => ['getVenueById', variables];
;

export const RateDocument = `
    mutation rate($args: CreateVenueRate!) {
  createVenueRate(createVenueRateInput: $args) {
    id
    rate
  }
}
    `;
export const useRateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RateMutation, TError, Types.RateMutationVariables, TContext>) =>
    useMutation<Types.RateMutation, TError, Types.RateMutationVariables, TContext>(
      ['rate'],
      axiosRequest<Types.RateMutation, Types.RateMutationVariables>(RateDocument),
      options
    );
useRateMutation.getKey = () => ['rate'];

export const VenueCommentDocument = `
    mutation venueComment($args: CreateVenueComment!) {
  createVenueComment(createVenueCommentInput: $args) {
    id
    comment
    likeCount
    replyCount
    parent {
      id
      comment
      likeCount
      replyCount
      createdAt
      updatedAt
    }
    user {
      id
      name
      email
      profile_image {
        key
        type
      }
    }
    createdAt
    updatedAt
  }
}
    `;
export const useVenueCommentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.VenueCommentMutation, TError, Types.VenueCommentMutationVariables, TContext>) =>
    useMutation<Types.VenueCommentMutation, TError, Types.VenueCommentMutationVariables, TContext>(
      ['venueComment'],
      axiosRequest<Types.VenueCommentMutation, Types.VenueCommentMutationVariables>(VenueCommentDocument),
      options
    );
useVenueCommentMutation.getKey = () => ['venueComment'];

export const GetVenueCommentsDocument = `
    query getVenueComments($filters: GetVenueComments!) {
  getVenueComments(getVenueCommentsArgs: $filters) {
    id
    comment
    likeCount
    replyCount
    parent {
      id
      comment
      likeCount
      replyCount
      createdAt
      updatedAt
    }
    user {
      id
      name
      email
      profile_image {
        key
        type
      }
    }
    createdAt
    updatedAt
  }
}
    `;
export const useGetVenueCommentsQuery = <
      TData = Types.GetVenueCommentsQuery,
      TError = unknown
    >(
      variables: Types.GetVenueCommentsQueryVariables,
      options?: UseQueryOptions<Types.GetVenueCommentsQuery, TError, TData>
    ) =>
    useQuery<Types.GetVenueCommentsQuery, TError, TData>(
      ['getVenueComments', variables],
      axiosRequest<Types.GetVenueCommentsQuery, Types.GetVenueCommentsQueryVariables>(GetVenueCommentsDocument).bind(null, variables),
      options
    );
useGetVenueCommentsQuery.document = GetVenueCommentsDocument;


useGetVenueCommentsQuery.getKey = (variables: Types.GetVenueCommentsQueryVariables) => ['getVenueComments', variables];
;

export const GetVenueRatesDocument = `
    query getVenueRates($id: Int!) {
  getVenueRatesCountWeb(venueId: $id) {
    ratesTotalCount
    venueRatesGrouped {
      rate
      rateGroupCount
    }
  }
}
    `;
export const useGetVenueRatesQuery = <
      TData = Types.GetVenueRatesQuery,
      TError = unknown
    >(
      variables: Types.GetVenueRatesQueryVariables,
      options?: UseQueryOptions<Types.GetVenueRatesQuery, TError, TData>
    ) =>
    useQuery<Types.GetVenueRatesQuery, TError, TData>(
      ['getVenueRates', variables],
      axiosRequest<Types.GetVenueRatesQuery, Types.GetVenueRatesQueryVariables>(GetVenueRatesDocument).bind(null, variables),
      options
    );
useGetVenueRatesQuery.document = GetVenueRatesDocument;


useGetVenueRatesQuery.getKey = (variables: Types.GetVenueRatesQueryVariables) => ['getVenueRates', variables];
;
