import React, { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Loading } from '../components/atom';
import { Layout } from '../components/organism';
import { useAuthState } from '../contexts/AuthProvider';

export const PrivateLayout = () => {
  const { state } = useAuthState();

  if (!state.isLoggedIn) {
    return <Navigate to="/login" />;
  }
  return (
    <Layout isLoggedIn={state.isLoggedIn}>
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </Layout>
  );
};
