import React, { useRef } from 'react';
import dayjs from 'dayjs';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import styles from './VenueComments.module.scss';
import { GetVenueCommentsQuery } from '../../../api/query/types';
import AccountIcon from '../../../assets/icons/account.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrowRight.svg';
import { createAmazonImageUrl, getImageQuality } from '../../../utils/image';
import { IconButton, Text } from '../../atom';

interface Props {
  comments?: GetVenueCommentsQuery['getVenueComments'];
  hasNext?: boolean;
  fetchNext?: Function;
}

const VenueComments = ({ comments, hasNext, fetchNext }: Props) => {
  const swiperRef = useRef<SwiperRef>(null);

  return (
    <div className={styles.comments}>
      <IconButton
        icon={<ArrowLeft fill="#e8eaed" />}
        onClick={() => swiperRef.current?.swiper.slidePrev()}
      />
      <Swiper
        ref={swiperRef}
        modules={[Navigation]}
        className={styles.content}
        onReachEnd={() => hasNext && fetchNext && fetchNext()}>
        {comments?.map(comment => (
          <SwiperSlide key={comment.id} className={styles.comment}>
            <div className={styles.left}>
              <img
                src={
                  comment.user.profile_image?.key
                    ? getImageQuality(createAmazonImageUrl(comment.user.profile_image.key), 'low')
                    : AccountIcon
                }
              />
              <Text className={styles.name}>{comment.user.name}</Text>
            </div>
            <div className={styles.right}>
              <div className={styles.info}>
                {/* <Rating count={rate} size={20} /> */}
                {!!comment.updatedAt && (
                  <Text className={styles.date}>
                    {dayjs(comment.updatedAt).format('dddd, MMMM DD, YYYY')}
                  </Text>
                )}
              </div>
              <Text className={styles.text}>{comment.comment}</Text>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <IconButton
        icon={<ArrowRight fill="#e8eaed" />}
        onClick={() => swiperRef.current?.swiper.slideNext()}
      />
    </div>
  );
};

export default VenueComments;
