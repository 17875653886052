import { CurrencyEnum, GetDiscountQuery } from '../api/query/types';

export type SelectItem = { id: number; value: string };
export type SwitchItem = { label: string; value: any };
export type EventImageQuality = 'high' | 'low';
export type EventVideoQuality = '320' | '768' | '1080';
export type EventMediaType = 'image' | 'video';
export enum Currency {
  USD = '$',
  EUR = '€',
  GBP = '£',
}

export type ITicketAmount = {
  amount: number;
  earlyBirdAmount?: number | null | undefined;
  earlyBirdCount?: number | null | undefined;
  currency: CurrencyEnum;
};

export type Discount = GetDiscountQuery['findDiscountByCodeAndVenueId'];

export type Action<T> = {
  type: T;
  payload?: any;
};

export type ArrayItemType<T> = T extends Array<infer U> ? U : unknown;
