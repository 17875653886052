import React, { FC, memo } from 'react';
import styles from './Error.module.scss';
import Text from '../Text/Text';

interface Props {
  info: string;
  icon: JSX.Element;
}

const Error: FC<Props> = ({ info, icon }) => {
  return (
    <div className={styles.error}>
      {icon}
      <Text className={styles.info}>{info}</Text>
    </div>
  );
};
export default memo(Error);
