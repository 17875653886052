import React, { FC, useEffect, useRef, useState } from 'react';
import { IVenueListFragment, Maybe } from '../../../api/query/types';
import { useAllVenuesPreviewQuery } from '../../../api/query/venue';
import { Texts } from '../../../assets/constants/texts';
import { useDebounce } from '../../../hooks/useDebounce';
import { useInfiniteQuery } from '../../../hooks/useInfiniteQuery';
import { Select } from '../../molecule';

interface Props {
  search?: boolean;
  onSelect?: (item: Maybe<IVenueListFragment>) => void;
}

const ITEMS_PER_PAGE = 30;

const VenueSelect: FC<Props> = ({ search, onSelect }) => {
  const [searchText, setSearchText] = useState('');
  const observerTarget = useRef(null);

  const debText = useDebounce(searchText, 200);

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    useAllVenuesPreviewQuery,
    ({ pageParam = 0 }) => ({
      filters: {
        limit: ITEMS_PER_PAGE,
        offset: (pageParam as number) * ITEMS_PER_PAGE,
        searchValue: debText,
      },
    }),
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.listVenuesWeb.venues.length < ITEMS_PER_PAGE ? false : pages.length + 1,
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [fetchNextPage, hasNextPage, observerTarget]);

  const options = data?.pages.reduce<Array<IVenueListFragment>>(
    (prev, curr) => [...prev, ...curr.listVenuesWeb.venues],
    [],
  );

  return (
    <>
      <Select
        helperText={Texts.get_started.form.venue_name_helper}
        helperTextColor="rgba(28, 28, 28, 0.75)"
        placeholder={Texts.get_started.form.venue_name_placeholder}
        onChange={setSearchText}
        onSelect={onSelect}
        search={search}>
        {options?.map(option => <Select.Option key={option.id} item={option} />)}
        <div ref={observerTarget}></div>
      </Select>
    </>
  );
};

export default VenueSelect;
