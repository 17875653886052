import { useCallback } from 'react';
import { useInitialData } from '../contexts/InitialDataProvider';

export const useCategories = () => {
  const { categories } = useInitialData();

  const getCategoryName = useCallback(
    (id: number) => categories?.find(item => item.id === id)?.name,
    [categories],
  );

  return { categories, getCategoryName };
};
