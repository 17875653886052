import React, { createContext, FC, useContext, useReducer } from 'react';
import { useLocalStorageValue } from '../hooks/useLocalStorage';

type AuthAction = LogInAction | LogOutAction;

interface AuthState {
  isLoggedIn?: boolean;
}

export enum AuthActionType {
  LOG_IN = 'LOG_IN',
  LOG_OUT = 'LOG_OUT',
}

interface AuthContextValue {
  state: AuthState;
  dispatch: (action: LogInAction) => void;
  setToken: (item: string) => void;
}

interface LogInAction {
  type: AuthActionType;
}

interface LogOutAction {
  type: AuthActionType;
}

const initialState: AuthState = {
  isLoggedIn: false,
};

const AuthContext = createContext<AuthContextValue>({
  state: initialState,
  dispatch: () => {},
  setToken: () => {},
});

interface Props {
  children?: JSX.Element;
}

export const AuthProvider: FC<Props> = ({ children }) => {
  const { item: token, setItem } = useLocalStorageValue('hot-access-token');

  const [state, dispatch] = useReducer(reducer, { ...initialState, isLoggedIn: !!token });

  return (
    <AuthContext.Provider value={{ setToken: setItem, state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthState = () => useContext(AuthContext);

const reducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case AuthActionType.LOG_IN: {
      return {
        ...state,
        isLoggedIn: true,
      };
    }
    case AuthActionType.LOG_OUT: {
      localStorage.removeItem('hot-access-token');
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};
