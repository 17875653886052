import React, { FC } from 'react';
import styles from './DoubleTitle.module.scss';
import { Text } from '../../atom';

interface Props {
  title: string;
}

const DoubleTitle: FC<Props> = ({ title }) => {
  return (
    <div className={styles.double_title}>
      <Text className={styles.double_title_secondary}>{title}</Text>
      <Text className={styles.double_title_primary}>{title}</Text>
    </div>
  );
};

export default DoubleTitle;
