import React, { FC, memo, useCallback, useState } from 'react';
import styles from './Rating.module.scss';
import { ReactComponent as RatingSelectedIcon } from '../../../assets/icons/ratingSelected.svg';
import { ReactComponent as RatingUnselectedIcon } from '../../../assets/icons/ratingUnselected.svg';
import IconButton from '../IconButton/IconButton';

interface RatingProps {
  count: number;
  size?: number;
  selectable?: boolean;
  showCount?: boolean;
  onRate?: (rate: number) => Promise<any>;
}

const Rating: FC<RatingProps> = ({ count, size = 24, selectable = false, onRate }) => {
  const [rate, setRate] = useState<number | undefined>();

  const onChange = useCallback(
    (newRate: number) => {
      if (newRate !== rate) {
        setRate(newRate);
        onRate &&
          onRate(newRate).catch(() => {
            setRate(count);
          });
      }
    },
    [count, onRate, rate],
  );

  return (
    <div className={styles.rating} style={{ gap: size / 12 }}>
      {Array(5)
        .fill(0)
        .map((_, index) => (
          <IconButton
            className={!selectable ? styles.not_selectable : ''}
            key={index}
            icon={
              index < (rate || count) ? (
                <RatingSelectedIcon height={size} key={index} />
              ) : (
                <RatingUnselectedIcon height={size} key={index} />
              )
            }
            onClick={() => selectable && onChange(index + 1)}
          />
        ))}
    </div>
  );
};

export default memo(Rating);
