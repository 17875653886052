import * as Types from './types';

import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { axiosRequest } from '../axiosHelper';
export const ITicketFragmentDoc = `
    fragment ITicket on Ticket {
  id
  name
  description
  count
  soldCount
  reserveCount
  amount
  saleAmount
  currency
  startTime
  endTime
  media {
    key
  }
  feedId
  ticketableId
  ticketableType
  earlyBirdAmount
  earlyBirdCount
  availableEarlyBird
}
    `;
export const TicketByEventIdDocument = `
    query ticketByEventId($eventId: Int!) {
  findTicketByEventId(eventId: $eventId) {
    ...ITicket
  }
}
    ${ITicketFragmentDoc}`;
export const useTicketByEventIdQuery = <
      TData = Types.TicketByEventIdQuery,
      TError = unknown
    >(
      variables: Types.TicketByEventIdQueryVariables,
      options?: UseQueryOptions<Types.TicketByEventIdQuery, TError, TData>
    ) =>
    useQuery<Types.TicketByEventIdQuery, TError, TData>(
      ['ticketByEventId', variables],
      axiosRequest<Types.TicketByEventIdQuery, Types.TicketByEventIdQueryVariables>(TicketByEventIdDocument).bind(null, variables),
      options
    );
useTicketByEventIdQuery.document = TicketByEventIdDocument;


useTicketByEventIdQuery.getKey = (variables: Types.TicketByEventIdQueryVariables) => ['ticketByEventId', variables];
;

export const GetDiscountDocument = `
    query getDiscount($code: String!, $venueId: Int!) {
  findDiscountByCodeAndVenueId(code: $code, venueId: $venueId) {
    id
    name
    discount
    type
  }
}
    `;
export const useGetDiscountQuery = <
      TData = Types.GetDiscountQuery,
      TError = unknown
    >(
      variables: Types.GetDiscountQueryVariables,
      options?: UseQueryOptions<Types.GetDiscountQuery, TError, TData>
    ) =>
    useQuery<Types.GetDiscountQuery, TError, TData>(
      ['getDiscount', variables],
      axiosRequest<Types.GetDiscountQuery, Types.GetDiscountQueryVariables>(GetDiscountDocument).bind(null, variables),
      options
    );
useGetDiscountQuery.document = GetDiscountDocument;


useGetDiscountQuery.getKey = (variables: Types.GetDiscountQueryVariables) => ['getDiscount', variables];
;

export const CancelCheckoutSessionDocument = `
    mutation cancelCheckoutSession($args: CancelCheckoutSessionInput!) {
  cancelCheckoutSession(cancelCheckoutSessionArgs: $args) {
    status
    message
  }
}
    `;
export const useCancelCheckoutSessionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CancelCheckoutSessionMutation, TError, Types.CancelCheckoutSessionMutationVariables, TContext>) =>
    useMutation<Types.CancelCheckoutSessionMutation, TError, Types.CancelCheckoutSessionMutationVariables, TContext>(
      ['cancelCheckoutSession'],
      axiosRequest<Types.CancelCheckoutSessionMutation, Types.CancelCheckoutSessionMutationVariables>(CancelCheckoutSessionDocument),
      options
    );
useCancelCheckoutSessionMutation.getKey = () => ['cancelCheckoutSession'];

export const BuyTicketDocument = `
    mutation buyTicket($args: OrderTicketInputWeb!) {
  orderTicketWeb(input: $args) {
    clientSecret
    note {
      earlyBirdAvailableCount
      currency
      amount
      boughtEarlyBirdQuantity
    }
  }
}
    `;
export const useBuyTicketMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.BuyTicketMutation, TError, Types.BuyTicketMutationVariables, TContext>) =>
    useMutation<Types.BuyTicketMutation, TError, Types.BuyTicketMutationVariables, TContext>(
      ['buyTicket'],
      axiosRequest<Types.BuyTicketMutation, Types.BuyTicketMutationVariables>(BuyTicketDocument),
      options
    );
useBuyTicketMutation.getKey = () => ['buyTicket'];
