import React, { FC, InputHTMLAttributes, memo, useMemo, useRef } from 'react';
import styles from './FilePicker.module.scss';
import { Texts } from '../../../assets/constants/texts';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
import IconButton from '../IconButton/IconButton';
import Text from '../Text/Text';

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  // key?: string;

  helperText?: string;
  helperTextColor?: string;
  baseClassName?: string;
  inputClassName?: string;
  errorText?: string;
  placeholderColor?: string;
  image?: string | null;
  onRemove?: () => void;
}

const FilePicker: FC<TextInputProps> = ({
  helperText,
  inputClassName,
  helperTextColor,
  image,
  onRemove,
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const UploadMask = useMemo(
    () => (
      <div className={styles.upload}>
        <Text className={styles.text}>{Texts.common.upload_title}</Text>
        {image ? (
          <IconButton
            className={styles.trash}
            icon={<TrashIcon />}
            onClick={() => {
              if (ref.current) {
                ref.current.value = '';
              }
              onRemove && onRemove();
            }}
          />
        ) : null}
      </div>
    ),
    [image, onRemove],
  );

  return (
    <>
      <span className={styles.file_picker}>
        {helperText ? (
          <Text style={{ color: helperTextColor }} className={styles.helperText}>
            {helperText}
          </Text>
        ) : null}
        <div className={[styles.input, inputClassName].join(' ')}>
          <label htmlFor={rest.id}>{image ? <img src={image!} /> : <UploadIcon />}</label>
          {UploadMask}
          <input
            ref={ref}
            type={'file'}
            // defaultValue={props.disabled ? undefined : props.defaultValue}
            {...rest}
          />
        </div>
      </span>
      {/* <ErrorText text={props.errortext} /> */}
    </>
  );
};

export default memo(FilePicker);
