import * as Types from './types';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { axiosRequest } from '../axiosHelper';
export const ITicketFragmentDoc = `
    fragment ITicket on Ticket {
  id
  name
  description
  count
  soldCount
  reserveCount
  amount
  saleAmount
  currency
  startTime
  endTime
  media {
    key
  }
  feedId
  ticketableId
  ticketableType
  earlyBirdAmount
  earlyBirdCount
  availableEarlyBird
}
    `;
export const IEventFragmentDoc = `
    fragment IEvent on Event {
  id
  name
  description
  startDate
  endDate
  address
  bandName
  price
  venueId
  hasTicket
  isSoldOut
  hasEarlyBirdTickets
  timeZone
  media {
    type
    key
    fileName
    contentLength
    sizes
  }
  tickets {
    ...ITicket
  }
  profitMarginAmount
  savedByMe
  color
}
    ${ITicketFragmentDoc}`;
export const ITicketPreviewFragmentDoc = `
    fragment ITicketPreview on Ticket {
  amount
  earlyBirdAmount
  earlyBirdCount
  currency
}
    `;
export const IEventPreviewFragmentDoc = `
    fragment IEventPreview on Event {
  id
  name
  startDate
  endDate
  address
  bandName
  hasEarlyBirdTickets
  timeZone
  price
  media {
    type
    key
    fileName
    contentLength
    sizes
  }
  tickets {
    ...ITicketPreview
  }
  profitMarginAmount
  savedByMe
  hasTicket
  isSoldOut
  color
}
    ${ITicketPreviewFragmentDoc}`;
export const TopEventsDocument = `
    query topEvents {
  getLast20Events {
    ...IEventPreview
  }
}
    ${IEventPreviewFragmentDoc}`;
export const useTopEventsQuery = <
      TData = Types.TopEventsQuery,
      TError = unknown
    >(
      variables?: Types.TopEventsQueryVariables,
      options?: UseQueryOptions<Types.TopEventsQuery, TError, TData>
    ) =>
    useQuery<Types.TopEventsQuery, TError, TData>(
      variables === undefined ? ['topEvents'] : ['topEvents', variables],
      axiosRequest<Types.TopEventsQuery, Types.TopEventsQueryVariables>(TopEventsDocument).bind(null, variables),
      options
    );
useTopEventsQuery.document = TopEventsDocument;


useTopEventsQuery.getKey = (variables?: Types.TopEventsQueryVariables) => variables === undefined ? ['topEvents'] : ['topEvents', variables];
;

export const AllEventsDocument = `
    query allEvents($filters: ListEventsWebInput!) {
  listEventsWeb(listEventsArgs: $filters) {
    events {
      ...IEvent
    }
    count
  }
}
    ${IEventFragmentDoc}`;
export const useAllEventsQuery = <
      TData = Types.AllEventsQuery,
      TError = unknown
    >(
      variables: Types.AllEventsQueryVariables,
      options?: UseQueryOptions<Types.AllEventsQuery, TError, TData>
    ) =>
    useQuery<Types.AllEventsQuery, TError, TData>(
      ['allEvents', variables],
      axiosRequest<Types.AllEventsQuery, Types.AllEventsQueryVariables>(AllEventsDocument).bind(null, variables),
      options
    );
useAllEventsQuery.document = AllEventsDocument;


useAllEventsQuery.getKey = (variables: Types.AllEventsQueryVariables) => ['allEvents', variables];
;

export const GetEventByIdDocument = `
    query getEventById($id: Int!) {
  findEventWeb(id: $id) {
    ...IEvent
  }
}
    ${IEventFragmentDoc}`;
export const useGetEventByIdQuery = <
      TData = Types.GetEventByIdQuery,
      TError = unknown
    >(
      variables: Types.GetEventByIdQueryVariables,
      options?: UseQueryOptions<Types.GetEventByIdQuery, TError, TData>
    ) =>
    useQuery<Types.GetEventByIdQuery, TError, TData>(
      ['getEventById', variables],
      axiosRequest<Types.GetEventByIdQuery, Types.GetEventByIdQueryVariables>(GetEventByIdDocument).bind(null, variables),
      options
    );
useGetEventByIdQuery.document = GetEventByIdDocument;


useGetEventByIdQuery.getKey = (variables: Types.GetEventByIdQueryVariables) => ['getEventById', variables];
;

export const GetFavoriteEventsDocument = `
    query getFavoriteEvents($filters: ListFavoritesByTypeInput!) {
  listFavoriteEvents(listFavoritesByTypeInput: $filters) {
    count
    events {
      ...IEventPreview
    }
  }
}
    ${IEventPreviewFragmentDoc}`;
export const useGetFavoriteEventsQuery = <
      TData = Types.GetFavoriteEventsQuery,
      TError = unknown
    >(
      variables: Types.GetFavoriteEventsQueryVariables,
      options?: UseQueryOptions<Types.GetFavoriteEventsQuery, TError, TData>
    ) =>
    useQuery<Types.GetFavoriteEventsQuery, TError, TData>(
      ['getFavoriteEvents', variables],
      axiosRequest<Types.GetFavoriteEventsQuery, Types.GetFavoriteEventsQueryVariables>(GetFavoriteEventsDocument).bind(null, variables),
      options
    );
useGetFavoriteEventsQuery.document = GetFavoriteEventsDocument;


useGetFavoriteEventsQuery.getKey = (variables: Types.GetFavoriteEventsQueryVariables) => ['getFavoriteEvents', variables];
;
