import React, { FC, memo, PropsWithChildren } from 'react';
import styles from './Modal.module.scss';

interface Props {
  visible?: boolean;
  onClose?: () => void;
}

const Modal: FC<PropsWithChildren<Props>> = ({ visible, onClose, children }) => {
  return (
    visible && (
      <>
        <div onClick={onClose} className={styles.modal}></div>
        <div className={styles.modal_container}>{children}</div>
      </>
    )
  );
};

export default memo(Modal);
