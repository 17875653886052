import * as Types from './types';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { axiosRequest } from '../axiosHelper';
export const IUserFragmentDoc = `
    fragment IUser on User {
  id
  name
  email
  profile_image {
    key
    type
  }
  background_image {
    key
    type
  }
}
    `;
export const SignUpDocument = `
    mutation signUp($args: CreateUserInput!) {
  createUser(createUserData: $args) {
    user {
      ...IUser
    }
    token
  }
}
    ${IUserFragmentDoc}`;
export const useSignUpMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SignUpMutation, TError, Types.SignUpMutationVariables, TContext>) =>
    useMutation<Types.SignUpMutation, TError, Types.SignUpMutationVariables, TContext>(
      ['signUp'],
      axiosRequest<Types.SignUpMutation, Types.SignUpMutationVariables>(SignUpDocument),
      options
    );
useSignUpMutation.getKey = () => ['signUp'];

export const LoginDocument = `
    mutation login($userCredentials: UserLoginDto!) {
  login(userCredentials: $userCredentials) {
    user {
      ...IUser
    }
    token
  }
}
    ${IUserFragmentDoc}`;
export const useLoginMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.LoginMutation, TError, Types.LoginMutationVariables, TContext>) =>
    useMutation<Types.LoginMutation, TError, Types.LoginMutationVariables, TContext>(
      ['login'],
      axiosRequest<Types.LoginMutation, Types.LoginMutationVariables>(LoginDocument),
      options
    );
useLoginMutation.getKey = () => ['login'];

export const UpdatePasswordDocument = `
    mutation updatePassword($args: UpdatePasswordInput!) {
  updatePassword(updatePasswordInput: $args) {
    user {
      ...IUser
    }
    token
  }
}
    ${IUserFragmentDoc}`;
export const useUpdatePasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdatePasswordMutation, TError, Types.UpdatePasswordMutationVariables, TContext>) =>
    useMutation<Types.UpdatePasswordMutation, TError, Types.UpdatePasswordMutationVariables, TContext>(
      ['updatePassword'],
      axiosRequest<Types.UpdatePasswordMutation, Types.UpdatePasswordMutationVariables>(UpdatePasswordDocument),
      options
    );
useUpdatePasswordMutation.getKey = () => ['updatePassword'];

export const LoginWithSocialDocument = `
    mutation loginWithSocial($userCredentials: LoginWithSocialInput!) {
  loginWithSocial(userCredentials: $userCredentials) {
    user {
      id
      name
      email
      followers
      followings
      online
    }
    token
  }
}
    `;
export const useLoginWithSocialMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.LoginWithSocialMutation, TError, Types.LoginWithSocialMutationVariables, TContext>) =>
    useMutation<Types.LoginWithSocialMutation, TError, Types.LoginWithSocialMutationVariables, TContext>(
      ['loginWithSocial'],
      axiosRequest<Types.LoginWithSocialMutation, Types.LoginWithSocialMutationVariables>(LoginWithSocialDocument),
      options
    );
useLoginWithSocialMutation.getKey = () => ['loginWithSocial'];
