import React, { FC } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styles from './Link.module.scss';

interface Props extends NavLinkProps {
  isActive?: boolean;
}

const Link: FC<Props> = ({ isActive, ...rest }) => {
  return (
    <NavLink
      {...rest}
      className={[styles.link, isActive ? styles.link_active : '', rest.className].join(' ')}
    />
  );
};

export default Link;
