export const Texts = {
  OK: 'ok',
  GET_STARTED: 'Get started',
  LOGIN: 'Log in',
  EVENTS: 'Events',
  VENUES: 'Venues',
  ABOUT_US: 'About US',
  BLOG: 'Blog',
  TERM_CONDITION: 'Term & Condition',
  PRIVACY_POLICY: 'Privacy policy',
  DOWNLOAD: 'Download',
  CONTACT_US: 'Contact us',
  FAQ: 'FAQ',
  FAVORITES: 'Favorites',

  SUBSCRIBE: 'Subscribe',
  FROM: 'From',
  BOOK: 'Book',

  COPYRIGHT: 'Copyright 2005-2023 | All Rights Reserved ',

  EMAIL_PLACEHOLDER: 'E-mail',
  PASSWORD_PLACEHOLDER: 'Password:',
  CREATE_PASSWORD_HELPER: 'Create a password:',
  NAME_HELPER: 'Name:',
  EMAIL_HELPER: 'E-mail:',

  HOME_BANNER_TITLE: 'Welcome to Hotspotz\n\nYour Ultimate Nightlife and Entertainment Portal',
  HOME_NEWS_TICKER: 'WANT TO SELL YOUR EVENT? JOIN  NOW !!! ',
  NEWS_TICKER: 'Want to Promote your Event and Sell Tickets… Join Here!',
  HOME_BOOK_BUTTON: 'Book',
  HOME_TITLE_1: 'Events',
  HOME_TITLE_2: 'Best Venues',
  forwardThinking: {
    forward: 'Forward',
    thinking: 'Thinking',
    description: "Don't just search for a place to go, see who's out by using our app.",
    button: 'Download',
  },
  LOGIN_TITLE: 'Login',
  SIGNUP: 'Sign Up',

  REQUIRED_MESSAGE: 'This filed is required',
  PASSWORD_CONFIRM_ERROR_MESSAGE: 'Passwords do not match!',
  CALENDAR: 'Calendar',
  YESTERDAY: 'Yesterday',
  LAST_WEEK: 'Last week',
  LAST_MONTH: 'Last month',

  COFFEE: 'Coffee',
  NIGHT_CLUB: 'Night Club',
  PUB: 'Pub',
  OTHERS: 'Others',

  // Months

  JANUARY: 'January',
  FEBRUARY: 'February',
  MARCH: 'March',
  APRIL: 'April',
  MAY: 'May',
  JUNE: 'June',
  JULY: 'July',
  AUGUST: 'August',
  SEPTEMBER: 'September',
  OCTOBER: 'October',
  NOVEMBER: 'November',
  DECEMBER: 'December',

  errors: {
    require: 'This field is required',
    email: 'Email is not valid',
    user_password: 'Password is wrong',
    new_password: {
      min: 'Password must contain at least 6 characters long.',
      uppercase: 'Password must contain at least one uppercase letter.',
      lowercase: 'Password must contain at least one lowercase letter.',
      numeric: 'Password must contain at least one numeric character.',
      nonAlphanumeric: 'Password must contain at least one non-alphanumeric character.',
    },
    phoneNumber: 'Phone number is not valid',
    terms_of_use: 'You need to agree our terms of use to be able to proceed.',
  },

  common: {
    upload_title: 'upload picture',
  },

  header: {
    profile: {
      menu_1: 'Profile',
      menu_2: 'Logout',
    },
  },

  edit_profile: {
    title: 'Edit Profile',
    name_helper: 'Name:',
    name_placeholder: 'First name ...',
    email_helper: 'E-mail:',
    email_placeholder: '@gmail ...',
    profile_picture: 'Profile Picture',
    background_picture: 'Background Picture',
    save: 'Save',
    reset: 'Reset',
    change_password: {
      title: 'Change Password:',
      current: 'Current:',
      current_placeholder: '******',
      new: 'New:',
      new_placeholder: '******',
      repeat: 'Repeat:',
      repeat_placeholder: '******',
    },
  },

  // About Us
  about_us: {
    title: 'ABOUT US',
    subtitle_1: "DON'T JUST",
    subtitle_2: 'SEARCH',
    subtitle_3: "FOR A PLACE TO GO, SEE WHO'S OUT.",
    description_1:
      "At Hot Spotz, we're not your average event comparison site – we're your ultimate nightlife playground! We've got all the bases covered to ensure your nights out are as epic as they come. With our state-of-the-art search engine and the magic of “Venue Viewer”™ and “Drinks Express” technology with a dash of in-app social media, we're your one-stop party shop.",
    description_2:
      "But we're not stopping there! We're here to unite you, the Hot Spotz enthusiast, with the coolest DJs, hottest bands, trendiest venues, and the wildest promoters. Together, we're on a mission to redefine nightlife and turn it into everything you've ever wished it could be. Join the party, and let's make your nights unforgettable!",
  },

  contact_us: {
    title: 'Need Support ?',
    name_helper: 'Name:',
    name_placeholder: 'First name ...',
    phone_helper: 'Phone:',
    phone_placeholder: 'Phone ...',
    email_helper: 'E-mail:',
    email_placeholder: '@gmail ...',
    message_helper: 'Message:',
    message_placeholder: 'message ...',
    submit_button: 'Send',
  },

  get_started: {
    header: 'Create and sell tickets For your event',
    header_button: 'Request',

    modal_switch: {
      event_manager: 'Event Manager',
      venue_manager: 'Venue Manager',
    },

    form: {
      name_helper: 'Full name:',
      name_placeholder: 'Full name ...',
      email_helper: 'Email:',
      email_placeholder: 'Email',
      phone_helper: 'Phone number:',
      phone_placeholder: 'Phone number',
      message_helper: 'Your message:',
      message_placeholder: '',
      venue_name_helper: 'Venue name:',
      venue_name_placeholder: 'Venue name',
      venue_address_helper: 'Venue address',
      venue_address_placeholder: 'Venue address',
      checkbox: {
        text1: 'I agree to the',
        text2: 'terms of use ',
      },
      positive_button: 'send',
      negative_button: 'cancel',
      link: 'Already admin ?',
    },

    1: {
      title: 'Become an organizer',
      text_1: 'Create an venue or event manager account',
      text_2: 'Become an organizer',
      text_3: 'Create & sell tickets for your events',
    },
    2: {
      title: 'Promote your event',
      text_1: 'Make your events more popular',
      text_2: 'Get closer to your audience & share your events',
      text_3: 'Engage people from all over the world',
    },
    3: {
      title: 'Become a Hotspotzian',
      text_1: 'Register your venue in Hot Spotz and make it more famous',
      text_2: 'Organize or host events',
      text_3: 'Become an important part of Hot Spotz community',
    },
    4: {
      title: 'Analise your venue and event',
      text_1: 'Get real time sales reports & graphs',
      text_2: 'Manage your events anytime you need',
      text_3: 'Become a top seller',
    },
  },
  favorites: {
    events: 'Events',
    venues: 'Venues',
  },

  event: {
    time: 'Time',
    place: 'Place',
    start_day: 'Start day',
    end_day: 'End day',
    description: 'Description',
  },

  venue: {
    tabs: {
      1: 'Description',
      2: 'Hours',
      3: 'Events',
    },

    desc_title: 'About us',
    working_hours: {
      day: 'Day',
      open: 'Open',
      close: 'Close',
      notes: 'Notes',
    },
    rate_us: 'Rate us',
    new_comment_helper: 'Your review here',
    button_title: 'Submit',
  },

  ticket: {
    title: 'BUY TICKET',
    count: 'Ticket count',
    promo_code: 'Promo code',
    promo_code_apply: 'Apply',
    details_time: 'Time',
    original_price: 'Original price',
    discounted_price: 'Discounted price',
    booking_fee: 'Booking fee',
    total_price: 'Total price',
    free: 'Free',
    button_title: 'Buy',
  },

  confirm: {
    details_title: 'Your Details',
    ticket_title: 'Your Order',
    card_helper: 'Payment Details',
    password_helper:
      'Please create a password to access your tickets online and for faster checkout in the future',
    submit_button: 'Confirm booking',
    cancel_button: 'Cancel Order',
  },

  paymentDetails: {
    title: 'Payment Details',
    name_on_card: 'Name on card:',
    card_number: 'Card number:',
    expiration: 'Expiration:',
    cvv: 'CVV:',
    submit: 'Pay',

    success_text:
      'Thank you! \n Your order is confirmed.\n You will receive a confirmation email about your order with all the data needed. \nYou can always reach us at \n',
  },
};

export const monthNames = [
  Texts.JANUARY,
  Texts.FEBRUARY,
  Texts.MARCH,
  Texts.APRIL,
  Texts.MAY,
  Texts.JUNE,
  Texts.JULY,
  Texts.AUGUST,
  Texts.SEPTEMBER,
  Texts.OCTOBER,
  Texts.NOVEMBER,
  Texts.DECEMBER,
];
