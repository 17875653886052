import React, { FC, ReactNode } from 'react';
import styles from './Text.module.scss';

interface TextProps {
  children?: ReactNode;
  className?: string;
  style?: object;
}

const Text: FC<TextProps> = ({ children, className, style }) => {
  return (
    <span className={[styles.text, className].join(' ')} style={style}>
      {children}
    </span>
  );
};

export default Text;
