import React, { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';
import styles from './IconButton.module.scss';
import Text from '../Text/Text';

interface IconButtonProps {
  className?: string;
  icon?: ReactNode;
  text?: string;
  direction?: 'horizontal' | 'vertical';
}

const IconButton = forwardRef<
  HTMLButtonElement,
  IconButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, icon, text, direction = 'vertical', ...rest }, ref) => {
  return (
    <button
      ref={ref}
      style={{ flexDirection: direction === 'horizontal' ? 'row' : 'column' }}
      className={[styles.icon_button, className].join(' ')}
      {...rest}>
      {icon}
      {!!text && <Text>{text}</Text>}
    </button>
  );
});

export default IconButton;
