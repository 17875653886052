import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './Header.module.scss';
import { Texts } from '../../../assets/constants/texts';
import { ReactComponent as LikeIcon } from '../../../assets/icons/like.svg';
import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/icons/profile.svg';
import { AuthActionType, useAuthState } from '../../../contexts/AuthProvider';
import { useOAuth } from '../../../hooks/useOAuth';
import { Button, IconButton, Link, Text } from '../../atom';

const profileMenuItems = [
  {
    type: 'profile',
    title: Texts.header.profile.menu_1,
  },
  {
    type: 'logout',
    title: Texts.header.profile.menu_2,
  },
];

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { state, dispatch } = useAuthState();
  const [accountMenuVisible, setAccountMenuVisible] = useState(false);
  const { signOut } = useOAuth();

  const toggleAccountMenu = useCallback(() => setAccountMenuVisible(prev => !prev), []);
  const handleMenuItem = useCallback(
    (type: string) => {
      toggleAccountMenu();

      switch (type) {
        case 'profile': {
          navigate('/profile');
          break;
        }

        case 'logout': {
          signOut();
          dispatch({ type: AuthActionType.LOG_OUT });
          break;
        }

        default:
          break;
      }
    },
    [dispatch, navigate, signOut, toggleAccountMenu],
  );

  return (
    <nav className={styles.header}>
      <div className={['container', styles.header_content].join(' ')}>
        <div className={styles.header_left}>
          <Link to="/events" isActive={pathname.includes('events')}>
            {Texts.EVENTS}
          </Link>
          <Link to="/venues" isActive={pathname.includes('venues')}>
            {Texts.VENUES}
          </Link>
          <Button
            className={styles.header_get_started}
            buttonType="positive"
            text={Texts.GET_STARTED}
            onClick={() => navigate('/get-started')}
          />
        </div>
        <Link to="/" className={styles.header_logo}>
          <Logo height={42} />
        </Link>
        <div className={styles.header_right}>
          <Link to="/about-us" isActive={pathname.includes('about-us')}>
            {Texts.ABOUT_US}
          </Link>
          <Link to="/blog" isActive={pathname.includes('blog')}>
            {Texts.BLOG}
          </Link>
          {state.isLoggedIn ? (
            <>
              <Link to="/favorites">
                <LikeIcon width={32} height={32} />
              </Link>
              <div className={styles.profile}>
                <IconButton
                  icon={<ProfileIcon width={32} height={32} />}
                  onClick={toggleAccountMenu}
                />
                {accountMenuVisible && (
                  <ul className={styles.menu}>
                    {profileMenuItems.map((item, index, array) => (
                      <li
                        key={item.type}
                        className={[
                          styles.menu_item,
                          index === 0
                            ? styles.menu_item_top
                            : index === array.length - 1
                              ? styles.menu_item_bottom
                              : '',
                        ].join(' ')}
                        onClick={() => handleMenuItem(item.type)}>
                        <Text>{item.title}</Text>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </>
          ) : (
            <Button buttonType="negative" onClick={() => navigate('/login')} text={Texts.LOGIN} />
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;
