import * as Types from './types';

import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { axiosRequest } from '../axiosHelper';
export const IUserFragmentDoc = `
    fragment IUser on User {
  id
  name
  email
  profile_image {
    key
    type
  }
  background_image {
    key
    type
  }
}
    `;
export const GetMeDocument = `
    query getMe {
  getMe {
    ...IUser
  }
}
    ${IUserFragmentDoc}`;
export const useGetMeQuery = <
      TData = Types.GetMeQuery,
      TError = unknown
    >(
      variables?: Types.GetMeQueryVariables,
      options?: UseQueryOptions<Types.GetMeQuery, TError, TData>
    ) =>
    useQuery<Types.GetMeQuery, TError, TData>(
      variables === undefined ? ['getMe'] : ['getMe', variables],
      axiosRequest<Types.GetMeQuery, Types.GetMeQueryVariables>(GetMeDocument).bind(null, variables),
      options
    );
useGetMeQuery.document = GetMeDocument;


useGetMeQuery.getKey = (variables?: Types.GetMeQueryVariables) => variables === undefined ? ['getMe'] : ['getMe', variables];
;

export const UpdateUserDocument = `
    mutation updateUser($args: UpdateUserInput!) {
  updateUser(updateUser: $args) {
    ...IUser
  }
}
    ${IUserFragmentDoc}`;
export const useUpdateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateUserMutation, TError, Types.UpdateUserMutationVariables, TContext>) =>
    useMutation<Types.UpdateUserMutation, TError, Types.UpdateUserMutationVariables, TContext>(
      ['updateUser'],
      axiosRequest<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>(UpdateUserDocument),
      options
    );
useUpdateUserMutation.getKey = () => ['updateUser'];

export const RequestToBecomeManagerDocument = `
    mutation requestToBecomeManager($args: CreateAdminRequestInput!) {
  createAdminRequest(createAdminRequestInput: $args) {
    status
    message
  }
}
    `;
export const useRequestToBecomeManagerMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RequestToBecomeManagerMutation, TError, Types.RequestToBecomeManagerMutationVariables, TContext>) =>
    useMutation<Types.RequestToBecomeManagerMutation, TError, Types.RequestToBecomeManagerMutationVariables, TContext>(
      ['requestToBecomeManager'],
      axiosRequest<Types.RequestToBecomeManagerMutation, Types.RequestToBecomeManagerMutationVariables>(RequestToBecomeManagerDocument),
      options
    );
useRequestToBecomeManagerMutation.getKey = () => ['requestToBecomeManager'];

export const GetPresignedUrlDocument = `
    mutation getPresignedUrl($getPreSignedUrlInput: GetPreSignedUrlInput!) {
  getPresignedUrl(getPreSignedUrlInput: $getPreSignedUrlInput) {
    url
    key
  }
}
    `;
export const useGetPresignedUrlMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.GetPresignedUrlMutation, TError, Types.GetPresignedUrlMutationVariables, TContext>) =>
    useMutation<Types.GetPresignedUrlMutation, TError, Types.GetPresignedUrlMutationVariables, TContext>(
      ['getPresignedUrl'],
      axiosRequest<Types.GetPresignedUrlMutation, Types.GetPresignedUrlMutationVariables>(GetPresignedUrlDocument),
      options
    );
useGetPresignedUrlMutation.getKey = () => ['getPresignedUrl'];

export const LikeDocument = `
    mutation like($args: MakeFavoriteInput!) {
  makeFavorite(makeFavoriteInput: $args) {
    id
  }
}
    `;
export const useLikeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.LikeMutation, TError, Types.LikeMutationVariables, TContext>) =>
    useMutation<Types.LikeMutation, TError, Types.LikeMutationVariables, TContext>(
      ['like'],
      axiosRequest<Types.LikeMutation, Types.LikeMutationVariables>(LikeDocument),
      options
    );
useLikeMutation.getKey = () => ['like'];

export const UnlikeDocument = `
    mutation unlike($args: DeleteInput!) {
  deleteFromFavorite(deleteInput: $args) {
    message
  }
}
    `;
export const useUnlikeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UnlikeMutation, TError, Types.UnlikeMutationVariables, TContext>) =>
    useMutation<Types.UnlikeMutation, TError, Types.UnlikeMutationVariables, TContext>(
      ['unlike'],
      axiosRequest<Types.UnlikeMutation, Types.UnlikeMutationVariables>(UnlikeDocument),
      options
    );
useUnlikeMutation.getKey = () => ['unlike'];

export const SubscribeDocument = `
    mutation subscribe($email: String!) {
  createSubscriber(email: $email) {
    status
    message
  }
}
    `;
export const useSubscribeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SubscribeMutation, TError, Types.SubscribeMutationVariables, TContext>) =>
    useMutation<Types.SubscribeMutation, TError, Types.SubscribeMutationVariables, TContext>(
      ['subscribe'],
      axiosRequest<Types.SubscribeMutation, Types.SubscribeMutationVariables>(SubscribeDocument),
      options
    );
useSubscribeMutation.getKey = () => ['subscribe'];

export const ContactUsDocument = `
    mutation contactUs($args: CreateContactInfoInput!) {
  createContactInfo(createContactInfoInput: $args) {
    status
    message
  }
}
    `;
export const useContactUsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ContactUsMutation, TError, Types.ContactUsMutationVariables, TContext>) =>
    useMutation<Types.ContactUsMutation, TError, Types.ContactUsMutationVariables, TContext>(
      ['contactUs'],
      axiosRequest<Types.ContactUsMutation, Types.ContactUsMutationVariables>(ContactUsDocument),
      options
    );
useContactUsMutation.getKey = () => ['contactUs'];
