import React, { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { EventProvider } from '../contexts/EventProvider';
import { VenueProvider } from '../contexts/VenueProvider';
import { PrivateLayout } from '../layout/PrivateLayout';
import { PublicLayout } from '../layout/PublicLayout';

const AboutUs = lazy(() => import('../pages/AboutUs/AboutUs'));
const Blogs = lazy(() => import('../pages/Blogs/Blogs'));
const SingleBlog = lazy(() => import('../pages/SingleBlog/SingleBlog'));
const ConfirmPayment = lazy(() => import('../pages/ConfirmPayment/ConfirmPayment'));
const ContactUs = lazy(() => import('../pages/ContactUs/ContactUs'));
const Events = lazy(() => import('../pages/Events/Events'));
const FAQ = lazy(() => import('../pages/Terms&Privacy/FAQ'));
const Favorites = lazy(() => import('../pages/Favorites/Favorites'));
const GetStarted = lazy(() => import('../pages/GetStarted/GetStarted'));
const Home = lazy(() => import('../pages/Home/Home'));
const Login = lazy(() => import('../pages/Login/Login'));
const NotFound = lazy(() => import('../pages/NotFound/NotFound'));
const PaymentDetails = lazy(() => import('../pages/PaymentDetails/PaymentDetails'));
const PrivacyPolicy = lazy(() => import('../pages/Terms&Privacy/PrivacyPolicy'));
const Profile = lazy(() => import('../pages/Profile/Profile'));
const Register = lazy(() => import('../pages/Register/Register'));
const TermAndCondition = lazy(() => import('../pages/Terms&Privacy/TermAndCondition'));
const SingleEvent = lazy(() => import('../pages/SingleEvent/SingleEvent'));
const Venues = lazy(() => import('../pages/Venues/Venues'));
const SingleVenue = lazy(() => import('../pages/SingleVenue/SingleVenue'));

export default () =>
  useRoutes([
    {
      path: '/',
      element: <PublicLayout />,
      children: [
        { path: '/', element: <Home /> },
        { path: '/login', element: <Login /> },
        { path: '/register', element: <Register /> },
        { path: '/events', element: <Events /> },
        { path: '/venues', element: <Venues /> },
        { path: '/about-us', element: <AboutUs /> },
        { path: '/contact-us', element: <ContactUs /> },
        { path: '/blog', element: <Blogs /> },
        { path: '/blog/:blogId', element: <SingleBlog /> },
        { path: '/term-and-condition', element: <TermAndCondition /> },
        { path: '/privacy_policy', element: <PrivacyPolicy /> },
        { path: '/FAQ', element: <FAQ /> },
        { path: '/get-started', element: <GetStarted /> },
        {
          element: <EventProvider />,
          children: [
            { path: '/event/:eventId', element: <SingleEvent /> },
            { path: '/event/:eventId/payment', element: <PaymentDetails /> },
            { path: '/event/:eventId/confirm', element: <ConfirmPayment /> },
          ],
        },
        {
          element: <VenueProvider />,
          children: [{ path: '/venue/:venueId', element: <SingleVenue /> }],
        },

        {
          path: '/404',
          element: <NotFound />,
        },
      ],
    },
    {
      path: '/',
      element: <PrivateLayout />,
      children: [
        { path: '/favorites', element: <Favorites /> },
        { path: '/profile', element: <Profile /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" /> },
  ]);
