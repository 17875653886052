import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './Calendar.module.scss';
import './customCalendar.scss';
import { monthNames, Texts } from '../../../assets/constants/texts';
import { ReactComponent as LeftArrow } from '../../../assets/icons/arrowLeft.svg';
import { ReactComponent as RightArrow } from '../../../assets/icons/arrowRight.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
import { Button, Text } from '../../atom';

dayjs.extend(utc);

interface Props {
  className?: string;
  onSubmit: (dates: [Date | null, Date | null]) => void;
  start?: Date;
  end?: Date;
}

const calendarItems = [
  'calendar_button',
  'calendar_button_icon',
  'calendar_ok',
  'container',
  'container_footer',
  'navigation',
  'navigation_1',
  'navigation_2',
  'navigation_3',
];

const Calendar: FC<Props> = ({ className, onSubmit, start, end }) => {
  const [visible, setVisible] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(start || null);
  const [endDate, setEndDate] = useState<Date | null>(end || null);

  const resetCalendar = useCallback(() => {
    setStartDate(start || null);
    setEndDate(end || null);
  }, [end, start]);

  const onClickOutside = useCallback(
    (e: any) => {
      if (!calendarItems.includes(e.target.id)) {
        setVisible(false);
        resetCalendar();
      }
    },
    [resetCalendar],
  );

  const renderHeader = useCallback(
    (params: ReactDatePickerCustomHeaderProps) => (
      <div className={styles.calendar_header}>
        <LeftArrow
          className={styles.calendar_header_icon}
          width={12}
          height={12}
          fill="#292D34"
          onClick={params.decreaseMonth}
        />
        <Text className={styles.calendar_header_month}>
          {`${monthNames[params.date.getMonth()]}    ${params.date.getFullYear()}`}
        </Text>
        <RightArrow
          className={styles.calendar_header_icon}
          width={12}
          height={12}
          fill="#292D34"
          onClick={params.increaseMonth}
        />
      </div>
    ),
    [],
  );

  const onDateChange = useCallback((dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);

    if (start?.getTime() !== end?.getTime()) {
      setEndDate(end);
    }
  }, []);

  const selectedDate = useMemo(() => {
    if (start && end) {
      return `${dayjs(start).format('D MMM').toLocaleUpperCase()} - ${dayjs(end)
        .format('D MMM')
        .toLocaleUpperCase()}`;
    }
    if (start) {
      return `${dayjs(start).format('D MMM').toLocaleUpperCase()}`;
    }
    if (end) {
      return `∞ - ${dayjs(end).format('D MMM').toLocaleUpperCase()}`;
    }
  }, [end, start]);

  const onOk = useCallback(() => {
    onSubmit([startDate, endDate]);
    setVisible(false);
  }, [endDate, onSubmit, startDate]);

  return (
    <div className={[styles.wrapper, className].join(' ')}>
      {selectedDate ? <Text className={styles.selected_dates}>{selectedDate} </Text> : null}
      <Button
        className={styles.calendar_button}
        id="calendar_button"
        buttonType="positive"
        icon={<CalendarIcon id="calendar_button_icon" fill="#ffffff" />}
        onClick={() => setVisible(prev => !prev)}
        text={Texts.CALENDAR}
      />

      {visible ? (
        <div id="container" className={styles.container}>
          <DatePicker
            calendarClassName={styles.calendar}
            renderCustomHeader={renderHeader}
            onChange={onDateChange}
            startDate={startDate}
            endDate={endDate}
            calendarStartDay={1}
            selectsRange
            selected={startDate}
            onClickOutside={onClickOutside}
            inline
          />
          <span id="container_footer" className={styles.container_footer}>
            <Button id="calendar_ok" buttonType="positive" text={Texts.OK} onClick={onOk} />
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default memo(Calendar);
