import axios, { AxiosRequestHeaders } from 'axios';

const apiClient = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const axiosRequest =
  <TData, TVariables>(
    query: string,
    headers?: AxiosRequestHeaders,
  ): ((variables?: TVariables) => Promise<TData>) =>
  (variables?: TVariables) =>
    apiClient
      .post<{ data: TData; errors: any }>(
        '',
        {
          query,
          variables,
        },
        { headers: headers },
      )
      .then(res => {
        if (res.data.errors) {
          throw new Error(res.data.errors[0].message);
        }
        return res.data.data;
      });

apiClient.interceptors.request.use(
  function (config) {
    if (config.headers) {
      const item = localStorage.getItem('hot-access-token');
      if (item) {
        config.headers.Authorization = `Bearer ${JSON.parse(item)}`;
      }

      config.headers.Mode = process.env.MODE;
    }
    return config;
  },
  function (error) {
    console.warn('Errrr', error);
    //TODO handle some errors
    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(function (response) {
  if (
    // eslint-disable-next-line no-prototype-builtins
    response.data.hasOwnProperty('errors') &&
    response.data.errors[0]?.extensions.response?.statusCode === 401
  ) {
    // logOut(true);
    return Promise.reject(response.data.errors[0].extensions.response.message);
  }
  return response;
});
