import React, {
  ChangeEventHandler,
  createContext,
  FocusEventHandler,
  MouseEventHandler,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './Select.module.scss';
import { Maybe } from '../../../api/query/types';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrowUp.svg';
import { Text } from '../../atom';

interface SelectItem {
  id: number | string;
  name: string;
}

interface Props<T> {
  placeholder?: string;
  helperText?: string;
  helperTextColor?: string;
  search?: boolean;
  onChange?: (text: string) => void;
  onSelect?: (item: Maybe<T>) => void;
  children?: ReactNode;
}

interface SelectState<T extends SelectItem> {
  activeOption: Maybe<T>;
  setActiveOption: Function;
  inputRef: RefObject<HTMLInputElement | null> | null;
  onSelect?: (item: Maybe<T>) => void;
}

const SelectContext = createContext<SelectState<any>>({
  activeOption: null,
  setActiveOption: () => {},
  inputRef: null,
  onSelect: () => {},
});

const Select = <T extends SelectItem>({
  helperText,
  helperTextColor,
  placeholder,
  search,
  onChange,
  onSelect,
  children,
}: Props<T>) => {
  const iconRef = useRef<SVGSVGElement>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [activeOption, setActiveOption] = useState<Maybe<T>>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const [text, setText] = useState<string | undefined>(
    activeOption?.name || (!search ? 'Select' : ''),
  );

  useEffect(() => {
    focused ? inputRef.current?.focus() : inputRef.current?.blur();
  }, [focused]);

  useEffect(() => {
    setFocused(false);
    setText(activeOption?.name);
  }, [activeOption]);

  useLayoutEffect(() => {
    if (iconRef.current && containerRef.current) {
      iconRef.current.style.transform = focused ? 'rotate(180deg)' : 'rotate(0deg)';
      iconRef.current.style.transition = 'all 0.3s linear';
      containerRef.current.style.maxHeight = focused ? '200px' : '0';
      containerRef.current.style.transition = 'all 0.3s linear';
    }
  }, [focused]);

  const onInputBlur: FocusEventHandler<HTMLInputElement> = useCallback(
    event => {
      event.preventDefault();
      if (focused) {
        setTimeout(() => setFocused(false), 100);
      }
    },
    [focused],
  );

  const onInputFocus: FocusEventHandler<HTMLInputElement> = useCallback(() => {
    if (search && !focused) {
      setFocused(true);
    }
  }, [focused, search]);

  // eslint-disable-next-line no-unused-vars
  const onChangeText: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      onChange && onChange(event.target.value);
      setText(event.target.value);
    },
    [onChange],
  );

  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    if (!focused) {
      setFocused(true);
    }
  }, [focused]);

  const onOptionSelect = useCallback(
    (item: Maybe<T>) => {
      setActiveOption(item);
      onSelect && onSelect(item);
    },
    [onSelect],
  );

  return (
    <SelectContext.Provider
      value={{ activeOption, setActiveOption: onOptionSelect, inputRef, onSelect: onSelect }}>
      <div className={styles.select}>
        {helperText ? (
          <Text style={{ color: helperTextColor }} className={styles.helper_text}>
            {helperText}
          </Text>
        ) : null}
        <div
          ref={containerRef}
          style={{
            border: focused ? '0.5px solid #E63335' : '0.5px solid transparent',
          }}
          onClick={onClick}
          className={styles.container}>
          <div
            className={styles.container_row}
            style={{ paddingBottom: (children as Array<any>)?.length ? 12 : 0 }}>
            <input
              className={styles.input}
              placeholder={placeholder}
              value={text}
              onFocus={onInputFocus}
              onChange={onChangeText}
              onBlur={onInputBlur}
              type={search ? 'text' : 'button'}
              autoComplete="off"
              ref={inputRef}
            />
            <ArrowIcon ref={iconRef} />
          </div>

          <div className={styles.container_children}>
            {activeOption && !search && (
              <Select.Option key={'none'} item={{ id: 'none', name: 'Select' }} />
            )}
            {children}
          </div>
        </div>
      </div>
    </SelectContext.Provider>
  );
};

export const useSelect = () => useContext(SelectContext);

interface OptionProps<T> {
  item: T;
}

const Option = <T extends SelectItem>(props: OptionProps<T>) => {
  const { activeOption, setActiveOption } = useSelect();

  const handleSelect = useCallback(() => {
    setActiveOption(props.item.id === 'none' ? null : props.item);
  }, [props.item, setActiveOption]);

  const isSelected = useMemo(
    () => activeOption?.id === props.item.id,
    [activeOption?.id, props.item.id],
  );

  return (
    <div
      className={isSelected ? [styles.option, styles.option_selected].join(' ') : styles.option}
      onClick={handleSelect}>
      <Text className={isSelected ? [styles.text, styles.text_selected].join(' ') : styles.text}>
        {props.item.name}
      </Text>
    </div>
  );
};

Select.Option = Option;

export default Select;
