import React, { FC, memo, useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import styles from './GetStartedForm.module.scss';
import {
  AdminReqRolesEnum,
  CreateAdminRequestInput,
  IVenueListFragment,
  Maybe,
} from '../../../api/query/types';
import { useRequestToBecomeManagerMutation } from '../../../api/query/user';
import { Texts } from '../../../assets/constants/texts';
import { Button, Checkbox, Input, Link, Loading, Text, TextArea } from '../../atom';
import VenueSelect from '../VenueSelect/VenueSelect';

type EventManagerFormData = {
  name: string;
  phoneNumber: string;
  email: string;
  message: string;
  agree: boolean;
};

type VenueManagerAdditionalFormData = {
  venue: IVenueListFragment;
};

type VenueManagerFormData = EventManagerFormData & VenueManagerAdditionalFormData;

type FormType<Condition extends boolean> = Condition extends false
  ? EventManagerFormData
  : VenueManagerFormData;

const eventManagerValidationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  phoneNumber: yup.string().required(),
  message: yup.string().required(),
  agree: yup.boolean().oneOf([true], Texts.errors.terms_of_use).required().default(false),
});
const venueManagerAdditionalValidationSchema = yup.object().shape({
  venue: yup.object().required(),
});

interface Props {
  isVenue: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
}

const GetStartedForm: FC<Props> = ({ isVenue = false, onCancel, onSuccess }) => {
  const { mutate, isLoading } = useRequestToBecomeManagerMutation({ onSuccess: onSuccess });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    // formState: { errors },
  } = useForm<FormType<typeof isVenue>>({
    resolver: yupResolver(
      isVenue
        ? eventManagerValidationSchema.concat(venueManagerAdditionalValidationSchema)
        : eventManagerValidationSchema,
    ),
  });

  const onSubmit: SubmitHandler<FormType<typeof isVenue>> = useCallback(
    data => {
      const { name, email, phoneNumber, message } = data;

      const reqData: CreateAdminRequestInput = {
        name,
        email,
        phoneNumber,
        message,
        role: isVenue ? AdminReqRolesEnum.VenueManager : AdminReqRolesEnum.Admin,
        venueId: (data as VenueManagerFormData).venue?.id,
      };

      // if (!isVenue) {
      //   delete reqData.venueId;
      // }

      mutate({
        args: reqData,
      });
    },
    [isVenue, mutate],
  );

  const onVenueSelect = useCallback(
    (venue: Maybe<IVenueListFragment>) => {
      if (venue) setValue('venue', venue);
    },
    [setValue],
  );

  return (
    <>
      {isLoading && <Loading />}
      <div className={styles.form}>
        <form autoComplete="off" autoCorrect="off">
          <div className={styles.left}>
            <Input
              registerValidation={{ ...register('name') }}
              helperText={Texts.get_started.form.name_helper}
              placeholder={Texts.get_started.form.name_placeholder}
              helperTextColor="rgba(28, 28, 28, 0.75)"
            />
            <Input
              registerValidation={{ ...register('email') }}
              helperText={Texts.get_started.form.email_helper}
              placeholder={Texts.get_started.form.email_placeholder}
              helperTextColor="rgba(28, 28, 28, 0.75)"
            />
            <Input
              registerValidation={{ ...register('phoneNumber') }}
              helperText={Texts.get_started.form.phone_helper}
              placeholder={Texts.get_started.form.phone_placeholder}
              helperTextColor="rgba(28, 28, 28, 0.75)"
            />

            {isVenue && (
              <>
                <VenueSelect search={true} onSelect={onVenueSelect} />

                <Input
                  registerValidation={{ ...register('venue.address') }}
                  helperText={Texts.get_started.form.venue_address_helper}
                  placeholder={Texts.get_started.form.venue_address_placeholder}
                  helperTextColor="rgba(28, 28, 28, 0.75)"
                  disabled
                  // disabled
                />
              </>
            )}
            <Checkbox
              className={styles.checkbox}
              initialValue={getValues('agree')}
              onValueChange={value => setValue('agree', value)}>
              <Text className={styles.bottom_text}>
                {Texts.get_started.form.checkbox.text1}
                <Link to={'/term-and-condition'}>
                  {' '}
                  <Text className={[styles.bottom_text, styles.link].join(' ')}>
                    {Texts.get_started.form.checkbox.text2}
                  </Text>{' '}
                </Link>
                *
              </Text>
            </Checkbox>
          </div>
          <div className={styles.right}>
            <TextArea
              registerValidation={{ ...register('message') }}
              helperText={Texts.get_started.form.message_helper}
              placeholder={Texts.get_started.form.message_placeholder}
              helperTextColor="rgba(28, 28, 28, 0.75)"
              autoComplete="on"
            />
          </div>
        </form>
        <div className={styles.button_container}>
          <Button
            buttonType="positive"
            type="submit"
            text={Texts.get_started.form.positive_button}
            onClick={handleSubmit(onSubmit)}
          />
          <Button
            buttonType="positive"
            text={Texts.get_started.form.negative_button}
            className={styles.negative}
            onClick={onCancel}
          />
        </div>
        <a href={process.env.REACT_APP_ADMIN_PANEL_URL} target="_blank" rel="noreferrer">
          <Text className={styles.link}>{Texts.get_started.form.link}</Text>
        </a>
      </div>
    </>
  );
};

export default memo(GetStartedForm);
