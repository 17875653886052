import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './NewsTicker.module.scss';
import { Texts } from '../../../assets/constants/texts';
import { Text } from '../../atom';

interface Props {
  className?: string;
  textClassName?: string;
  text?: string;
}

const NewsTicker: FC<Props> = ({ className, textClassName, text = Texts.NEWS_TICKER }) => {
  const navigate = useNavigate();
  const onClick = useCallback(() => navigate('/get-started'), [navigate]);
  return (
    <div onClick={onClick} className={styles.newsTicker}>
      <div className={[styles.newsTicker_background, className].join(' ')}></div>
      <Text className={[styles.newsTicker_text, textClassName].join(' ')}>{text}</Text>
    </div>
  );
};

export default NewsTicker;
