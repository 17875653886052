import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { IEventPreviewFragment, Maybe } from '../api/query/types';
import { ITicketAmount } from '../models/base';
dayjs.extend(utc);
export const multichoice = <T extends { id: number | string }>(
  item: T,
  items: Array<T>,
): Array<T> => {
  const isAlreadySelected = items?.find(i => i.id === item.id);
  return isAlreadySelected !== undefined
    ? items.filter(i => i.id !== isAlreadySelected.id)
    : items.concat(item);
};

export const sortTicketList = <T extends ITicketAmount>(tickets?: Maybe<Array<T>>) =>
  tickets?.sort((a, b) => {
    if (a.earlyBirdCount || b.earlyBirdCount) {
      return b.earlyBirdAmount! - a.earlyBirdAmount!;
    }
    return b.amount - a.amount;
  });

export const getMinPriceTicket = (tickets: IEventPreviewFragment['tickets']) => {
  const sorted = sortTicketList(tickets);

  return sorted?.length && sorted[0];
};

export const generateTicketTimeIntervalString = (start: string, end: string) =>
  `${dayjs.utc(start).format('HH:mm')}-${dayjs.utc(end).format('HH:mm')}`;

export const getCheapestTicket = (tickets: IEventPreviewFragment['tickets']) => {
  if (!tickets || tickets.length === 0)  return null;

  return tickets.reduce((minValue, currentValue) => {
    if (currentValue.amount < minValue) {
      minValue = currentValue.amount;
    }

    return minValue;
  }, tickets[0].amount);
}

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // @ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  if (/Macintosh|Mac OS X/.test(userAgent)) {
    return "macOS";
  }

  return "unknown";
}