import { useCallback, useMemo, useSyncExternalStore } from 'react';

const subscribe = (listener: any) => {
  window.addEventListener('storage', listener);

  return () => window.removeEventListener('storage', listener);
};

export const useLocalStorageValue = <T>(name: string) => {
  const getSnapshot = () => {
    return localStorage.getItem(name);
  };

  const value = useSyncExternalStore(subscribe, getSnapshot);

  const setItem = useCallback(
    (item: T) => localStorage.setItem(name, JSON.stringify(item)),
    [name],
  );

  const removeItem = useCallback(() => localStorage.removeItem(name), [name]);

  const item = useMemo(() => {
    try {
      return value ? JSON.parse(value) : null;
    } catch (error) {
      return ' ';
    }
  }, [value]);

  return { item, setItem, removeItem };
};
