import React from 'react';
import { useLottie } from 'lottie-react';
import styles from './Loading.module.scss';
import LoadingAnimation from '../../../assets/animations/loading.json';

const Loading = () => {
  const { View: Loader } = useLottie({ animationData: LoadingAnimation, loop: true });

  return <div className={styles.loading}>{Loader}</div>;
};

export default Loading;
