import {
  useInfiniteQuery as useInfinite,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
} from '@tanstack/react-query';
import { axiosRequest } from '../api/axiosHelper';

interface UseQueryFn<TData, TVariables> {
  (variables: TVariables, options?: UseQueryOptions<TData>): unknown;
  document: string;
  getKey: (variables: TVariables) => unknown[];
}

export type AdditionalArgument = {
  page: number;
  additionalData?: any;
};

export const useInfiniteQuery = <TData, TVariables>(
  useQuery: UseQueryFn<TData, TVariables>,
  getVariables: ({ pageParam }: { pageParam?: number | AdditionalArgument }) => TVariables,
  options?: UseInfiniteQueryOptions<TData, Error>,
): UseInfiniteQueryResult<TData, Error> =>
  useInfinite<TData, Error>(
    useQuery.getKey(getVariables({})),
    ({ pageParam }) =>
      axiosRequest<TData, TVariables>(useQuery.document)(getVariables({ pageParam })),
    {
      getNextPageParam: (_, allPages) => {
        return allPages.length;
      },
      ...options,
    },
  );
