import React, { createContext, FC, useContext } from 'react';
import { UseMutateAsyncFunction, UseMutateFunction } from '@tanstack/react-query';
import { useAuthState } from './AuthProvider';
import {
  Exact,
  GetPreSignedUrlInput,
  GetPresignedUrlMutation,
  IUserFragment,
  Maybe,
  UpdateUserInput,
  UpdateUserMutation,
} from '../api/query/types';
import {
  useGetMeQuery,
  useGetPresignedUrlMutation,
  useUpdateUserMutation,
} from '../api/query/user';

interface ProfileState {
  user?: Maybe<IUserFragment>;
  getMe?: Function;
  isLoading?: boolean;
  editUser: UseMutateFunction<
    UpdateUserMutation,
    unknown,
    Exact<{ args: UpdateUserInput }>,
    unknown
  >;
  getPresignedUrl: UseMutateAsyncFunction<
    GetPresignedUrlMutation,
    unknown,
    Exact<{
      getPreSignedUrlInput: GetPreSignedUrlInput;
    }>,
    unknown
  >;
}

const ProfileContext = createContext<ProfileState>({
  editUser: () => {},
  getPresignedUrl: async (): Promise<any> => {},
});

interface Props {
  children?: JSX.Element;
}

export const ProfileProvider: FC<Props> = ({ children }) => {
  const {
    state: { isLoggedIn },
  } = useAuthState();

  const {
    data: userData,
    refetch: getMe,
    isFetching: getUserLoading,
  } = useGetMeQuery({}, { enabled: isLoggedIn, refetchOnWindowFocus: false });
  const { mutate: editUser, isLoading, data: updatedUserData } = useUpdateUserMutation();
  const { mutateAsync: getPresignedUrl } = useGetPresignedUrlMutation();

  return (
    <ProfileContext.Provider
      value={{
        user: Object.assign(userData?.getMe || {}, updatedUserData?.updateUser),
        getMe,
        editUser,
        isLoading: getUserLoading || isLoading,
        getPresignedUrl,
      }}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);
