import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import * as yup from 'yup';
import styles from './Layout.module.scss';
import { useSubscribeMutation } from '../../../api/query/user';
import { bottomLinks } from '../../../assets/constants';
import { Texts } from '../../../assets/constants/texts';
import { ReactComponent as Facebook } from '../../../assets/icons/facebook.svg';
import { ReactComponent as Instagram } from '../../../assets/icons/instagram.svg';
import { ReactComponent as Youtube } from '../../../assets/icons/youtube.svg';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { Button, Input, Link, Text } from '../../atom';
import { Header, MobileHeader, NewsTicker } from '../../molecule';
interface Props {
  children?: JSX.Element;
  hasBottomNewsTicker?: boolean;
  isLoggedIn?: boolean;
}

const subscribeSchema = yup.object({
  email: yup.string().email('Email is not valid').required('Please write your email'),
});

const Layout: FC<Props> = ({ children, hasBottomNewsTicker = true }) => {
  const emailRef = useRef<HTMLInputElement>(null);
  const { pathname } = useLocation();
  const [search] = useSearchParams();
  const windowSize = useWindowSize();
  const [subscribeError, setSubscribeError] = useState(null);

  const isFromMobile = useMemo(() => search.get('mobile'), [search]);

  const { mutateAsync: subscribe } = useSubscribeMutation({});

  const handleSubscribe = useCallback(async () => {
    setSubscribeError(null);
    try {
      if (emailRef.current) {
        await subscribeSchema.validate({ email: emailRef.current?.value });
        await subscribe({ email: emailRef.current?.value! });
        (emailRef.current as any)?.clean();
      }
    } catch (err: any) {
      setSubscribeError(err.message);
    }

    // TODO: send email
  }, [subscribe]);

  const renderFallback = useCallback(() => {
    return null;
  }, []);

  return (
    <div className={styles.layout}>
      {!isFromMobile ? windowSize.width > 768 ? <Header /> : <MobileHeader /> : null}
      <ErrorBoundary fallbackRender={renderFallback}>
        <div className={styles.layout_children}>{children}</div>
      </ErrorBoundary>
      {!isFromMobile && (
        <footer className={styles.layout_footer}>
          {hasBottomNewsTicker && pathname !== '/' && (
            <NewsTicker className={styles.ticker} textClassName={styles.ticker_text} />
          )}
          <div className={styles.layout_footer_base}>
            <div className={['container', styles.layout_footer_base_content].join(' ')}>
              <div className={styles.links}>
                {bottomLinks.map((link, index) => (
                  <Link
                    key={index}
                    isActive={pathname.includes(link.to)}
                    to={link.to}
                    className={styles.link_item}>
                    {link.title}
                  </Link>
                ))}
              </div>
              <div className={styles.subscribe}>
                <Input
                  ref={emailRef}
                  placeholder={Texts.EMAIL_PLACEHOLDER}
                  errorText={subscribeError}
                  onChange={ev => {
                    ev.preventDefault();
                    setSubscribeError(null);
                  }}
                />
                <Button
                  buttonType="positive"
                  className={styles.subscribe_button}
                  text={Texts.SUBSCRIBE}
                  onClick={handleSubscribe}
                />
              </div>

              <div className={styles.social}>
                <Link to={''} className={styles.social_item}>
                  <Facebook />
                </Link>
                <Link to={''} className={styles.social_item}>
                  <Instagram />
                </Link>
                <Link to={''} className={styles.social_item}>
                  <Youtube />
                </Link>
              </div>

              <Text className={styles.copyright}>{Texts.COPYRIGHT}</Text>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
};

export default Layout;
