import { UseMutateAsyncFunction } from '@tanstack/react-query';
import axios from 'axios';
import { Exact, GetPreSignedUrlInput, GetPresignedUrlMutation } from '../api/query/types';
import { EventImageQuality, EventMediaType, EventVideoQuality } from '../models/base';

export const isBlob = (file: any): file is Blob => {
  return 'size' in file;
};

export const imageFromGoogleSource = (source?: string, width?: number) => {
  const widthParam = width ? `&maxwidth=${width}` : '';
  return source + widthParam;
};

export const createProfileImageFileName = (userId?: number, fileName?: string) =>
  `${process.env.REACT_APP_AWS_USER_MEDIA_BASE_PATH}${userId}/${process.env.REACT_APP_AWS_PROFILE_IMAGES_PATH}${fileName}`;

export const createBackgroundImageFileName = (userId?: number, fileName?: string) =>
  `${process.env.REACT_APP_AWS_USER_MEDIA_BASE_PATH}${userId}/${process.env.REACT_APP_AWS_BACKGROUND_IMAGES_PATH}${fileName}`;

export const createPostImageFileName = (userId?: number, postId?: number, fileName?: string) =>
  `${process.env.REACT_APP_AWS_USER_MEDIA_BASE_PATH}${userId}/${process.env.REACT_APP_AWS_POST_IMAGE_PATH}${postId}/${fileName}`;

export const createAmazonImageUrl = (fileName?: string | null) =>
  `${process.env.REACT_APP_AWS_STORAGE_URL}/${fileName}`;

export const createAmazonQRUrl = (fileName?: string | null) =>
  `${process.env.REACT_APP_AWS_STORAGE_URL}/${process.env.REACT_APP_AWS_TICKET_QR_PATH}${fileName}.png`;

export const getImageQuality = (imageURl: string, quality?: EventImageQuality) => {
  const pats = imageURl.split('/');
  const fileName = pats.pop();

  return `${pats.join('/')}/${quality}_${fileName}`;
};

export const getVideoQuality = (videoUrl: string, quality?: EventVideoQuality) => {
  const pats = videoUrl.split('/');
  const fileName = pats.pop();
  return `${pats.join('/')}/${quality}_${fileName}`;
};

const partial =
  (fn: Function, firstArg: string) =>
  (
    lastArgs: EventImageQuality | EventVideoQuality,
  ): ReturnType<typeof getImageQuality | typeof getVideoQuality> =>
    fn(firstArg, lastArgs);

const createPartialQualityFn = (url: string, type: EventMediaType): ReturnType<typeof partial> => {
  switch (type) {
    case 'image':
      return partial(getImageQuality, url);
    case 'video':
      return partial(getVideoQuality, url);
  }
};

export const generateEventMediaURL = (
  url: string,
  expectedSize: EventImageQuality | EventVideoQuality,
  sizes: Array<EventImageQuality | EventVideoQuality>,
  type: EventMediaType,
) => {
  const withURL = createPartialQualityFn(url, type);

  const index = sizes.findIndex(size => size === expectedSize);

  const size = index > -1 ? sizes[index] : sizes[sizes.length - 1];

  return withURL(size);
};

export const uploadS3viaPreSignerUrl = async ({
  fetcher,
  fileName,
  file,
}: {
  fetcher: UseMutateAsyncFunction<
    GetPresignedUrlMutation,
    unknown,
    Exact<{
      getPreSignedUrlInput: GetPreSignedUrlInput;
    }>,
    unknown
  >;
  fileName: string;
  file: File;
}) => {
  try {
    fetcher({
      getPreSignedUrlInput: { key: fileName },
    }).then(async res => axios.put(res.getPresignedUrl.url, file));
  } catch (err) {
    console.log(err);
  }
};
