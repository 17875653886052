import React, { FC, memo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import styles from './Event.module.scss';
import { FavoritesTypeEnum } from '../../../api/query/types';
// import { useLikeMutation, useUnlikeMutation } from '../../../api/query/user';
import { useLikeMutation, useUnlikeMutation } from '../../../api/query/user';
import { djColors } from '../../../assets/constants';
import { Texts } from '../../../assets/constants/texts';
import { ReactComponent as LikeIcon } from '../../../assets/icons/like.svg';
import EventImage from '../../../assets/images/Event.png';
import { useAuthState } from '../../../contexts/AuthProvider';
import { IconButton, Text } from '../../atom';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

interface Props {
  id: number;
  name?: string;
  img?: string;
  price?: number | null;
  date?: string;
  timezone?: string;
  address?: string | null;
  djs?: Array<string | null>;
  className?: string;
  isLiked?: boolean;
  currency?: string;
}
// 'Friday Jun 30th',
const Event: FC<Props> = ({
  id = 0,
  name = 'Event name',
  img = EventImage,
  price = '$100',
  date = new Date(),
  timezone,
  address = 'Gaia Restaurant',
  djs = ['DJ Name', 'DJ Name adas ada', 'DJ Name', 'DJ Name', 'DJ Name'],
  className,
  isLiked = false,
  currency = 'GBP',
}) => {
  const navigate = useNavigate();
  const {
    state: { isLoggedIn },
  } = useAuthState();
  const [liked, setLiked] = useState(isLiked);

  const { mutateAsync: like } = useLikeMutation();
  const { mutateAsync: unlike } = useUnlikeMutation();

  const handleLike = useCallback(() => {
    if (!isLoggedIn) {
      return navigate('/login');
    }

    setLiked(prev => !prev);

    if (!liked) {
      like({ args: { type: FavoritesTypeEnum.Event, eventId: id } }).catch(() => {
        setLiked(prev => !prev);
      });
    } else {
      unlike({ args: { id, type: FavoritesTypeEnum.Event } }).catch(() => {
        setLiked(prev => !prev);
      });
    }
  }, [id, isLoggedIn, like, liked, navigate, unlike]);

  return (
    <div className={[styles.event, className].join(' ')}>
      <img src={img} className={styles.photo} />

      <div className={styles.event_info}>
        <Text className={styles.event_info_name}>{name}</Text>
        <div className={styles.event_info_date_and_type}>
          <Text className={[styles.text, styles.date].join(' ')}>{`${dayjs
            .tz(date, timezone)
            .format('dddd MMM Do')}`}</Text>
          <Text className={[styles.text, styles.address].join(' ')}>{`@ ${address}`}</Text>
        </div>
        <div className={styles.event_info_djs}>
          {djs.map((dj, index) => (
            <div
              className={styles.container}
              key={index}
              style={{ backgroundColor: djColors[index] }}>
              <Text className={styles.text}>{dj}</Text>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.event_actions}>
        <div className={styles.price}>
          <>
            <Text className={[styles.text, styles.price_from].join(' ')}>{Texts.FROM}</Text>
            <Text className={[styles.text, styles.price_value].join(' ')}>
              {price ? `${currency} ${price}` : '-'}
            </Text>
          </>
        </div>
        <button onClick={() => navigate(`/event/${id}`)} className={styles.book}>
          <Text className={styles.title}>{Texts.BOOK}</Text>
        </button>
      </div>
      <span className={liked ? styles.liked : styles.like}>
        <IconButton icon={<LikeIcon />} onClick={handleLike} />
      </span>
    </div>
  );
};

export default memo(Event);
