import React, { useEffect, useMemo, useRef } from 'react';
import styles from './styles.module.scss';
import { IVenuePreviewFragment } from '../../../api/query/types';
import { useGetFavoriteVenuesQuery } from '../../../api/query/venue';
import { ReactComponent as EmptyFavoritesIcon } from '../../../assets/images/EmptyFavorites.svg';
import { useInfiniteQuery } from '../../../hooks/useInfiniteQuery';
import { createAmazonImageUrl, generateEventMediaURL } from '../../../utils/image';
import { Venue } from '../../molecule';

const ITEMS_PER_PAGE = 30;
const FavoriteVenues = () => {
  const observerTarget = useRef(null);
  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
    useGetFavoriteVenuesQuery,
    ({ pageParam = 0 }) => ({
      filters: {
        limit: ITEMS_PER_PAGE,
        offset: (pageParam as number) * ITEMS_PER_PAGE,
        // searchValue: debText,
      },
    }),
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.listFavoriteVenues.venues.length < ITEMS_PER_PAGE ? false : pages.length + 1,
      keepPreviousData: true,
    },
  );

  const venues = useMemo(
    () =>
      data?.pages.reduce<Array<IVenuePreviewFragment>>(
        (prev, curr) => [...prev, ...curr.listFavoriteVenues.venues],
        [],
      ),
    [data?.pages],
  );

  useEffect(() => {
    const ref = observerTarget.current;
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      },
      { threshold: 1 },
    );

    if (ref) {
      observer.observe(ref);
    }

    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
  }, [fetchNextPage, hasNextPage, observerTarget]);

  if (!venues?.length && !isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <EmptyFavoritesIcon />
      </div>
    );
  }

  return (
    <div className={styles.venues}>
      {venues?.map(item => (
        <Venue
          id={item.id}
          key={item.id}
          name={item.name}
          isLiked={item.savedByMe}
          image={
            item.featuredImageUrl
              ? generateEventMediaURL(
                  createAmazonImageUrl(item.featuredImageUrl.key),
                  'low',
                  item.featuredImageUrl.sizes as any,
                  item.featuredImageUrl.type as any,
                )
              : item.media?.length
                ? generateEventMediaURL(
                    createAmazonImageUrl(item.media[0].key),
                    'low',
                    item.media[0].sizes as any,
                    item.media[0].type as any,
                  )
                : undefined
          }
          categoryId={item.categoryId}
          rate={item.avgRate}
        />
      ))}
      <div ref={observerTarget}></div>
    </div>
  );
};

export default FavoriteVenues;
