import React, { memo, PropsWithChildren, ReactNode, useCallback, useRef, useState } from 'react';
import styles from './Switch.module.scss';
import { SwitchItem } from '../../../models/base';
import { Text } from '../../atom';

type SwitchItemWithComponent = SwitchItem & { component: ReactNode };

export const isWithComponent = (
  items: Array<SwitchItem | SwitchItemWithComponent>,
): items is Array<SwitchItemWithComponent> => {
  return items.every(item => 'component' in item);
};

interface Props<T> {
  items: Array<T>;
  activeItem?: T;
  onChange?: (item: T) => void;
  withHorizontalAnimation?: boolean;
}

const Switch = <T extends SwitchItem | SwitchItemWithComponent>({
  items,
  activeItem,
  withHorizontalAnimation = false,
  onChange,
  children,
}: PropsWithChildren<Props<T>>) => {
  const selectorRef = useRef<HTMLSpanElement>(null);
  const refs = useRef<Record<string, HTMLLIElement | null>>({});
  const contentRef = useRef<HTMLUListElement | null>(null);
  const [selected, setSelected] = useState<T>(activeItem || items[0]);
  const [itemWidth, setItemWidth] = useState(0);

  const getRef = useCallback(
    (ref: HTMLLIElement | null, value: any) => {
      if (refs.current) {
        if (value === selected.value) {
          setItemWidth(ref?.clientWidth || 0);
        }

        refs.current = { ...refs.current, [value]: ref };
      }
    },
    [selected.value],
  );

  const onClick = useCallback(
    (item: T) => {
      const ref = refs.current[item.value];
      if (ref && selectorRef.current) {
        selectorRef.current.style.transform = `translateX(${ref.offsetLeft}px)`;
        if (contentRef.current)
          contentRef.current.style.transform = `translateX(-${ref.offsetLeft ? 100 : 0}%)`;
      }
      onChange && onChange(item);

      setSelected(item);
    },
    [onChange],
  );

  return (
    <div className={styles.switch}>
      <ul className={styles.toggle}>
        {items.map(item => (
          <li
            ref={ref => getRef(ref, item.value)}
            className={styles.item}
            key={item.label}
            onClick={() => onClick(item)}>
            <Text className={selected.value === item.value ? styles.text_selected : styles.text}>
              {item.label}
            </Text>
          </li>
        ))}
        <span
          ref={selectorRef}
          style={{ width: itemWidth }}
          className={styles.toggle_selected}></span>
      </ul>
      {isWithComponent(items) ? (
        <div className={styles.content}>{(selected as SwitchItemWithComponent).component}</div>
      ) : (
        children
      )}
    </div>
  );
};

export default memo(Switch);
