import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import {
  GetVenueCommentsQuery,
  GetVenueRatesQuery,
  IVenueFragment,
  Maybe,
  RateMutation,
} from '../api/query/types';
import {
  useGetVenueByIdQuery,
  useGetVenueCommentsQuery,
  useGetVenueRatesQuery,
  useRateMutation,
  useVenueCommentMutation,
} from '../api/query/venue';
import { useInfiniteQuery } from '../hooks/useInfiniteQuery';

interface VenueContextState {
  venue?: Maybe<IVenueFragment>;
  isLoading: boolean;
  onRate?: (newRate: number) => Promise<RateMutation>;
  comments?: GetVenueCommentsQuery['getVenueComments'];
  hasNextCommentsPage?: boolean;
  fetchNextCommentsPage?: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<GetVenueCommentsQuery, Error>>;
  rates?: GetVenueRatesQuery['getVenueRatesCountWeb'];
  handleComment?: (comment: string) => void;
}

const VenueContext = createContext<VenueContextState>({
  isLoading: false,
});

const ITEMS_PER_PAGE = 30;

export const VenueProvider = () => {
  const { venueId } = useParams();

  const { data: venue, isLoading: venueLoading } = useGetVenueByIdQuery(
    { id: Number(venueId) },
    { refetchOnWindowFocus: false },
  );

  const { mutateAsync } = useRateMutation();

  const onRate = useCallback(
    (newRate: number) => mutateAsync({ args: { venueId: Number(venueId), rate: newRate } }),
    [mutateAsync, venueId],
  );

  const {
    data: venueComments,
    fetchNextPage: fetchNextCommentsPage,
    hasNextPage: hasNextCommentsPage,
    refetch,
  } = useInfiniteQuery(
    useGetVenueCommentsQuery,
    ({ pageParam = 0 }) => ({
      filters: {
        venueId: Number(venueId),
        limit: ITEMS_PER_PAGE,
        offset: (pageParam as number) * ITEMS_PER_PAGE,
      },
    }),
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.getVenueComments.length < ITEMS_PER_PAGE ? false : pages.length + 1,
      keepPreviousData: true,
    },
  );

  const comments = useMemo(
    () =>
      venueComments?.pages.reduce<GetVenueCommentsQuery['getVenueComments']>(
        (acc, curr) => [...acc, ...curr.getVenueComments],
        [],
      ),
    [venueComments?.pages],
  );

  const { data: rates } = useGetVenueRatesQuery({ id: Number(venueId) });

  const { mutateAsync: comment, isLoading: isCommentCreating } = useVenueCommentMutation();

  const handleComment = (text: string) => {
    comment({
      args: {
        comment: text.trim(),
        venueId: Number(venueId),
      },
    }).then(() => {
      refetch();
    });
  };

  return (
    <VenueContext.Provider
      value={{
        venue: venue?.getVenueWeb,
        isLoading: venueLoading || isCommentCreating,
        onRate,
        comments,
        fetchNextCommentsPage,
        hasNextCommentsPage,
        rates: rates?.getVenueRatesCountWeb,
        handleComment,
      }}>
      <Outlet />
    </VenueContext.Provider>
  );
};

export const useVenue = () => useContext(VenueContext);
