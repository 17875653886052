import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import styles from './TabNavigation.module.scss';
import { Text } from '../../atom';

interface TabItem {
  id: number | string;
  name: string;
  children?: ReactNode;
}

interface TabState {
  activeTab?: TabItem;
  setActiveTab: Function;
  onSelect?: (item: TabState) => void;
}

const TabsContext = createContext<TabState>({
  setActiveTab: () => {},
  onSelect: () => {},
});

interface Props {
  children?: ReactNode;
  className?: string;
}

const TabNavigation = ({ children, className }: Props) => {
  const initialValue = useMemo(() => {
    const items = React.Children.map(children, (child: any) => child.props);
    return items?.length ? items[0] : (children as any).props;
  }, [children]);

  const [activeTab, setActiveTab] = useState<TabItem>(initialValue);

  return (
    <TabsContext.Provider value={{ activeTab, setActiveTab }}>
      <div className={styles.tab_navigation}>
        <div className={[styles.tabs, className].join(' ')}>{children}</div>
        {activeTab.children}
      </div>
    </TabsContext.Provider>
  );
};

export const useTabNavigation = () => useContext(TabsContext);

const Item = (item: TabItem) => {
  const { activeTab, setActiveTab } = useTabNavigation();

  const handleSelect = useCallback(() => {
    setActiveTab(item);
  }, [item, setActiveTab]);

  const isSelected = useMemo(() => activeTab?.id === item.id, [activeTab?.id, item.id]);

  return (
    <div
      className={
        isSelected ? [styles.tabs_item, styles.tabs_item_selected].join(' ') : styles.tabs_item
      }
      onClick={handleSelect}>
      <Text className={isSelected ? [styles.text, styles.text_selected].join(' ') : styles.text}>
        {item.name}
      </Text>
    </div>
  );
};

TabNavigation.Item = Item;

export default TabNavigation;
