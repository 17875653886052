import React, { FC, memo, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Venue.module.scss';
import { FavoritesTypeEnum } from '../../../api/query/types';
import { useLikeMutation, useUnlikeMutation } from '../../../api/query/user';
import { ReactComponent as LikeIcon } from '../../../assets/icons/like.svg';
import { ReactComponent as Rate } from '../../../assets/icons/ratingSelected.svg';
import Img from '../../../assets/images/Venue.png';
import { useAuthState } from '../../../contexts/AuthProvider';
import { useCategories } from '../../../hooks/useCategories';
import { Button, IconButton, Rating, Text } from '../../atom';
import Image from '../../atom/Image/Image';

interface Props {
  id: number;
  name?: string;
  rate?: number;
  categoryId?: number | null;
  image?: string;
  showAddress?: boolean;
  isLiked?: boolean;
}

const Venue: FC<Props> = ({
  id,
  name = 'Venue Name',
  rate = 0,
  categoryId,
  image = Img,
  showAddress = false,
  isLiked = false,
}) => {
  const { getCategoryName } = useCategories();
  const navigate = useNavigate();
  const {
    state: { isLoggedIn },
  } = useAuthState();
  const [liked, setLiked] = useState(isLiked);

  const { mutateAsync: like } = useLikeMutation();
  const { mutateAsync: unlike } = useUnlikeMutation();

  const category = useMemo(
    () => (categoryId && categoryId >= 0 ? getCategoryName(categoryId) : ''),
    [categoryId, getCategoryName],
  );

  const handleLike: MouseEventHandler<HTMLButtonElement> = useCallback(
    event => {
      event.stopPropagation();
      if (!isLoggedIn) {
        return navigate('/login');
      }

      setLiked(prev => !prev);

      if (!liked) {
        like({ args: { type: FavoritesTypeEnum.Venue, venueId: id } }).catch(() => {
          setLiked(prev => !prev);
        });
      } else {
        unlike({ args: { id, type: FavoritesTypeEnum.Venue } }).catch(() => {
          setLiked(prev => !prev);
        });
      }
    },
    [id, isLoggedIn, like, liked, navigate, unlike],
  );

  return (
    <div className={styles.venue} onClick={() => navigate(`/venue/${id}`, { state: { name } })}>
      <Image src={image} fallbackImage={Img} />
      <div className={styles.venue_info}>
        <div className={styles.venue_info_left}>
          <Text className={styles.name}>{name}</Text>
          {category ? (
            <Button className={styles.type} disabled buttonType="positive" text={category} />
          ) : null}
        </div>
        <Rating count={rate} />
      </div>
      {showAddress && (
        <div className={styles.venue_address}>
          <Rate fill={'rgba(28, 28, 28, 0.88)'} />
          <Text className={styles.text}>Venue Address adders address address</Text>
        </div>
      )}
      <span className={liked ? styles.liked : styles.like}>
        <IconButton icon={<LikeIcon />} onClick={handleLike} />
      </span>
    </div>
  );
};

export default memo(Venue);
