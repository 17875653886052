import React, { ChangeEventHandler, FC, memo, useCallback } from 'react';
import styles from './Counter.module.scss';
import { ReactComponent as MinusIcon } from '../../../assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { IconButton, Input } from '../../atom';

interface Props {
  count: number;
  onChange: (value: number) => void;
  maxCount?: number;
}

const Counter: FC<Props> = ({ count, onChange, maxCount }) => {
  const onIncrease = useCallback(() => {
    const newCount = count + 1;
    if (!maxCount || newCount <= maxCount) {
      onChange(newCount);
    }
  }, [count, maxCount, onChange]);
  const onDecrease = useCallback(
    () => (count === 1 ? undefined : onChange(--count)),
    [count, onChange],
  );

  const handleInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    ev => {
      const text = ev.target.value;
      if (Number(text) < 1) {
        return onChange(1);
      }
      return onChange(Number(text));
    },
    [onChange],
  );
  return (
    <div className={styles.counter}>
      <IconButton className={styles.button} onClick={onDecrease} icon={<MinusIcon />} />

      <Input
        baseClassName={styles.inputContainer}
        inputClassName={styles.inputWrapper}
        className={styles.input}
        onChange={handleInput}
        value={count}
        onKeyPress={event => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
      />
      <IconButton className={styles.button} onClick={onIncrease} icon={<PlusIcon />} />
    </div>
  );
};

export default memo(Counter);
