import React, { memo, useEffect, useMemo, useRef } from 'react';
import styles from './styles.module.scss';
import { useGetFavoriteEventsQuery } from '../../../api/query/event';
import { GetFavoriteEventsQuery } from '../../../api/query/types';
import { ReactComponent as EmptyFavoritesIcon } from '../../../assets/images/EmptyFavorites.svg';
import { useInfiniteQuery } from '../../../hooks/useInfiniteQuery';
import { Currency } from '../../../models/base';
import { getMinPriceTicket } from '../../../utils/helpers';
import { createAmazonImageUrl, generateEventMediaURL } from '../../../utils/image';
import { Loading } from '../../atom';
import { Event } from '../../molecule';

const ITEMS_PER_PAGE = 30;

const FavoriteEvents = () => {
  const observerTarget = useRef(null);
  const { data, hasNextPage, fetchNextPage, isLoading } = useInfiniteQuery(
    useGetFavoriteEventsQuery,
    ({ pageParam = 0 }) => ({
      filters: {
        limit: ITEMS_PER_PAGE,
        offset: (pageParam as number) * ITEMS_PER_PAGE,
        // searchValue: debText,
      },
    }),
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.listFavoriteEvents.events.length < ITEMS_PER_PAGE ? false : pages.length + 1,
      keepPreviousData: true,
    },
  );

  const events = useMemo(
    () =>
      data?.pages.reduce<GetFavoriteEventsQuery['listFavoriteEvents']['events']>(
        (prev, curr) => [...prev, ...curr.listFavoriteEvents.events],
        [],
      ),
    [data?.pages],
  );

  useEffect(() => {
    const ref = observerTarget.current;
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      },
      { threshold: 1 },
    );

    if (ref) {
      observer.observe(ref);
    }

    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
  }, [fetchNextPage, hasNextPage, observerTarget]);

  if (!events?.length && !isLoading) {
    return (
      <div className={styles.empty}>
        <EmptyFavoritesIcon />
      </div>
    );
  }

  return (
    <div className={styles.events}>
      {isLoading && <Loading />}

      {events?.map((item: any) => {
        const ticket = getMinPriceTicket(item.tickets);
        return (
          <Event
            id={item.id}
            key={item.id}
            name={item.name}
            img={
              item.media?.length
                ? generateEventMediaURL(
                    createAmazonImageUrl(item.media[0].key),
                    'low',
                    item.media[0].sizes as any,
                    item.media[0].type as any,
                  )
                : undefined
            }
            isLiked={item.savedByMe}
            date={item.startDate}
            timezone={item.timeZone}
            address={item.address}
            djs={item.bandName ? [...item.bandName.split(',')] : []}
            price={ticket && (ticket.earlyBirdCount ? ticket.earlyBirdAmount : ticket.amount)}
            currency={Currency[ticket ? ticket.currency : 'GBP']}
          />
        );
      })}
    </div>
  );
};

export default memo(FavoriteEvents);
