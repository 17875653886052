import React, { FC, HTMLProps, ReactNode } from 'react';
// import Loading from '@Assets/Icons/Loading.svg';
import style from './Button.module.scss';

interface ButtonProps {
  buttonType?: 'positive' | 'negative';
  className?: string;
  icon?: ReactNode;
  text?: string;
  type?: 'button' | 'reset' | 'submit';
  isLoading?: boolean;
}

const Button: FC<ButtonProps & HTMLProps<HTMLButtonElement>> = ({
  buttonType,
  className,
  icon,
  type,
  text,
  ...rest
}) => {
  const rootClassName = [
    style.buttonComponent,
    buttonType === 'positive' ? style.buttonComponent_positive : style.buttonComponent_negative,
  ];

  return (
    <button type={type} className={[...rootClassName, className].join(' ')} {...rest}>
      <>
        {icon}
        {text}
      </>
    </button>
  );
};

export default Button;
